/*-------- Ribbons -------*/
.ribbon-1, .ribbon-2 {
	position: absolute;
	top: -6.1px;
	right: 10px;
	color: #fff;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 24px solid transparent;
		z-index: 9;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		font-size: 14px;
		line-height: 1;
		padding: 12px 8px 10px;
		border-top-right-radius: 8px;
		z-index: 9;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 6px;
			width: 6px;
			left: -6px;
			top: 0;
		}
		&:after {
			height: 6px;
			width: 8px;
			left: -8px;
			top: 0;
			border-radius: 8px 8px 0 0;
		}
	}
	i {
		color: #fff;
		font-size: 16px;
		padding-left: 7px;
		padding-right: 7px;
	}
}
.ribbon-3 {
	position: absolute;
	top: -6.1px;
	right: 10px;
	color: #fff;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 24px solid transparent;
		border-top: 13px solid #0ccad8;
		z-index: 9;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: #0ccad8;
		font-size: 14px;
		line-height: 1;
		padding: 12px 8px 10px;
		border-top-right-radius: 8px;
		z-index: 9;
	}
}
.ribbon-1 span:after {
	position: absolute;
	content: "";
}
.ribbon-3 {
	span {
		&:before {
			position: absolute;
			content: "";
			height: 6px;
			width: 6px;
			left: -6px;
			top: 0;
			background: #0ccad8;
		}
		&:after {
			height: 6px;
			width: 8px;
			left: -8px;
			top: 0;
			border-radius: 8px 8px 0 0;
			background: #0ccad8;
		}
	}
	i {
		color: #fff;
		font-size: 16px;
		padding-left: 7px;
		padding-right: 7px;
	}
}
.ribbone {
	width: 100%;
	position: relative;
	background-size: cover;
	text-transform: uppercase;
	color: #fff;
}
.ribbon1 {
	position: absolute;
	top: -6.1px;
	left: 10px;
	z-index: 1;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 21px solid transparent;
		border-top: 13px solid #f8463f;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: #f8463f;
		font-size: 14px;
		line-height: 1.2;
		padding: 16px 8px 10px;
		border-top-right-radius: 8px;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 6px;
			width: 6px;
			left: -6px;
			top: 0;
			background: #f8463f;
		}
		&:after {
			height: 6px;
			width: 8px;
			left: -8px;
			top: 0;
			border-radius: 8px 8px 0 0;
			background: #c02031;
		}
	}
}
.arrow-ribbon {
	color: #fff;
	padding: 3px 8px;
	position: absolute;
	top: 10px;
	left: 0;
	z-index: 98;
	font-size: 18px;
	&.bg-purple:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		right: -15px;
		border-top: 15px solid transparent;
		border-left: 15px solid #6d33ff;
		border-bottom: 15px solid transparent;
		width: 0;
	}
	&.bg-danger:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		right: -15px;
		border-top: 15px solid transparent;
		border-left: 15px solid #fe6567;
		border-bottom: 15px solid transparent;
		width: 0;
	}
	&.bg-secondary:before, &.bg-primary:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		right: -15px;
		border-top: 15px solid transparent;
		border-bottom: 15px solid transparent;
		width: 0;
	}
	&.bg-success:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		right: -15px;
		border-top: 15px solid transparent;
		border-left: 15px solid #3eb750;
		border-bottom: 15px solid transparent;
		width: 0;
	}
	&.bg-info:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		right: -15px;
		border-top: 15px solid transparent;
		border-left: 15px solid #23bcb7;
		border-bottom: 15px solid transparent;
		width: 0;
	}
	&.bg-warning:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		right: -15px;
		border-top: 15px solid transparent;
		border-left: 15px solid #ffb609;
		border-bottom: 15px solid transparent;
		width: 0;
	}
	&.bg-pink:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		right: -15px;
		border-top: 15px solid transparent;
		border-left: 15px solid #ff2b88;
		border-bottom: 15px solid transparent;
		width: 0;
	}
}
.arrow-ribbon2 {
	color: #fff;
	padding: 3px 8px;
	position: absolute;
	top: 10px;
	left: -1px;
	z-index: 98;
	font-size: 30px;
	&:before {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
		right: -24px;
		border-top: 24px solid transparent;
		border-bottom: 24px solid transparent;
		width: 0;
	}
}
.ribbon {
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
	z-index: 10;
}
.power-ribbon {
	width: 56px;
	height: 56px;
	overflow: hidden;
	position: absolute;
	z-index: 10;
	span {
		position: absolute;
		display: block;
		width: 82px;
		padding: 8px 0;
		color: #fff;
		font: 500 16px/1 Lato, sans-serif;
		text-shadow: 0 1px 1px rgba(49, 45, 101, 0.2);
		text-transform: capitalize;
		text-align: center;
	}
}
.power-ribbon-top-left {
	span {
		right: -5px;
		top: 1px;
		transform: rotate(-45deg);
		i {
			transform: rotate(45deg);
			padding-top: 2px;
			padding-left: 7px;
		}
	}
	top: -6px;
	left: -9px;
}
.ribbon {
	&::after, &::before {
		position: absolute;
		z-index: -1;
		content: '';
		display: block;
		border: 5px solid;
	}
	span {
		position: absolute;
		display: block;
		width: 225px;
		padding: 8px 0;
		box-shadow: 0 5px 10px rgba(49, 45, 101, 0.1);
		color: #fff;
		text-shadow: 0 1px 1px rgba(49, 45, 101, 0.2);
		text-transform: capitalize;
		text-align: center;
	}
}
.ribbon-top-left {
	top: -10px;
	left: -10px;
	&::after {
		border-top-color: transparent;
		border-left-color: transparent;
	}
	&::before {
		border-top-color: transparent;
		border-left-color: transparent;
		top: 0;
		right: 30px;
	}
	&::after {
		bottom: 50px;
		left: 0;
	}
	span {
		right: -8px;
		top: 30px;
		transform: rotate(-45deg);
	}
}
.ribbon-top-right {
	top: -10px;
	right: -10px;
	&::after {
		border-top-color: transparent;
		border-right-color: transparent;
	}
	&::before {
		border-top-color: transparent;
		border-right-color: transparent;
		top: 0;
		left: 36px;
	}
	&::after {
		bottom: 36px;
		right: 0;
	}
	span {
		left: -8px;
		top: 30px;
		transform: rotate(45deg);
	}
}
.badge-offer {
	position: absolute;
	top: 0;
	left: 15px;
	z-index: 2;
	padding: 5px;
	font-weight: 400;
	text-align: center;
	color: #fff;
	font-size: 16px;
	height: 33px;
	width: 50px;
	margin-bottom: 0;
	&.bg-danger:after {
		border-top: 12px solid #fe6567;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-secondary:after {
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-primary:after {
		border-top: 12px solid #f6f7fb;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-success:after {
		border-top: 12px solid #3eb750;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-info:after {
		border-top: 12px solid #23bcb7;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-warning:after {
		border-top: 12px solid #ffb609;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-purple:after {
		border-top: 12px solid #6d33ff;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-pink:after {
		border-top: 12px solid #ff2b88;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
}
.badge-offer1 {
	position: absolute;
	top: 0;
	right: 15px;
	z-index: 2;
	padding: 5px;
	font-weight: 400;
	text-align: center;
	color: #fff;
	font-size: 16px;
	height: 33px;
	width: 50px;
	margin-bottom: 0;
	&.bg-danger:after {
		border-top: 12px solid #fe6567;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-secondary:after {
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-primary:after {
		border-top: 12px solid #f6f7fb;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-success:after {
		border-top: 12px solid #3eb750;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-info:after {
		border-top: 12px solid #23bcb7;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-warning:after {
		border-top: 12px solid #ffb609;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-purple:after {
		border-top: 12px solid #6d33ff;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
	&.bg-pink:after {
		border-top: 12px solid #ff2b88;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		content: "";
		height: 0;
		left: 0;
		position: absolute;
		top: 33px;
		width: 0;
	}
}
/*-------- Ribbons -------*/
