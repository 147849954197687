/*-------- Alerts -------*/

.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 5px;
	font-size: .9375rem;
	&:first-child {
		margin-bottom: 1rem !important;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.alert-heading {
	color: inherit;
}
.alert-link {
	font-weight: 500;
	&:hover, a:hover {
		text-decoration: underline;
	}
}
.alert-dismissible {
	padding-right: 3.90625rem;
}
hr.message-inner-separator {
	clear: both;
	margin-top: 10px;
	margin-bottom: 13px;
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, rgba(49, 45, 101, 0), rgba(49, 45, 101, 0.15), rgba(49, 45, 101, 0));
	background-image: -moz-linear-gradient(left, rgba(49, 45, 101, 0), rgba(49, 45, 101, 0.15), rgba(49, 45, 101, 0));
	background-image: -ms-linear-gradient(left, rgba(49, 45, 101, 0), rgba(49, 45, 101, 0.15), rgba(49, 45, 101, 0));
	background-image: -o-linear-gradient(left, rgba(49, 45, 101, 0), rgba(49, 45, 101, 0.15), rgba(49, 45, 101, 0));
}
.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	padding: .75rem 1.25rem;
	color: inherit;
}
.alert-success {
	color: #6e716f;
	background-color: #cdecd1;
	border-color: #cdecd1;
	hr {
		border-top-color: #c5e7a4;
	}
	.alert-link {
		color: #3eb750;
	}
}
.alert-info {
	color: #606161;
	background-color: #e2f6f6;
	border-color: #e2f6f6;
	hr {
		border-top-color: #b3dcf9;
	}
	.alert-link {
		color: #23bcb7;
	}
}
.alert-warning {
	color: #656463;
	background-color: #fff6e1;
	border-color: #fff6e1;
	hr {
		border-top-color: #fae8a4;
	}
	.alert-link {
		color: #ffb609;
	}
}
.alert-danger {
	color: #807575;
	background-color: #ffebeb;
	border-color: #ffebeb;
	hr {
		border-top-color: #ecacab;
	}
	.alert-link {
		color: #fe6567;
	}
}
.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe;
	hr {
		border-top-color: #ececf6;
	}
	.alert-link {
		color: #686868;
	}
}
.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;
	hr {
		border-top-color: #b9bbbe;
	}
	.alert-link {
		color: #040505;
	}
}
/*-------- Alerts -------*/