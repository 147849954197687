/*-------- Input Styles -------*/

.input-lg {
	height: 46px !important;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 5px;
}
.input-sm {
	height: 30px !important;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 5px;
}
.select2-lg {
	.select2-container .select2-selection--single {
		height: 2.875rem !important;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 45px !important;
	}
}
.select2-sm {
	.select2-container .select2-selection--single {
		height: 1.775rem !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 27px !important;
		}
		.select2-selection__arrow b {
			top: 80% !important;
		}
	}
}
select.form-control {
	&.select-lg {
		height: 46px !important;
		padding: 10px 16px !important;
		font-size: 17px !important;
		line-height: 1.3333333 !important;
		border-radius: 3px !important;
	}
	&.select-sm {
		height: 30px !important;
		padding: 0px 10px 1px 10px !important;
		line-height: 1 !important;
		border-radius: 3px !important;
	}
}
.form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 14px;
	line-height: 1.6;
	color: #3f3e50;
	height: 40px;
	background-color: $form-background;
	background-clip: padding-box;
	border: 1px solid $border-color;
	border-radius: 5px;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	opacity: 1;
	&.border-white {
		border: 1px solid rgb(255, 255, 255) !important;
	}
}
.form-group.border-white .select2-container--default .select2-selection--single {
	border: 1px solid rgb(255, 255, 255) !important;
}
.search-background .select2-container--default .select2-selection--single {
	border: 1px solid rgb(255, 255, 255) !important;
}
.select2-container--default {
	.select2-selection--single {
		background-color: $form-background;
		border: 1px solid $border-color !important;
		border-radius: 4px;
	}
	&.select2-container--focus .select2-selection--multiple {
		color: #3d4e67;
		background-color: $form-background;
		border-color: #4c8aec;
		outline: 0;
	}
	.select2-selection--multiple {
		background-color: $form-background;
		border: 1px solid $border-color !important;
		border-radius: 4px;
		cursor: text;
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid $border-color !important;
	}
	.select2-selection--multiple .select2-selection__choice {
		background-color: $border-color !important;
		border: 1px solid $border-color !important;
	}
}
@media screen and (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
}
.form-control {
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}
	&:focus {
		color: #6f6e6e;
		background-color: #f8f7fc;
		border-color:$border-color;
		outline: 0;
		box-shadow: none;
	}
	&::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
		color: #cecde0;
		opacity: 1;
	}
	&:disabled, &[readonly] {
		background-color: $border-color;
		opacity: 1;
	}
}
select.form-control:not([size]):not([multiple]) {
	height: 2.425rem;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #3f3e50 !important;
	line-height: 39px !important;
}
select.form-control:focus::-ms-value {
	color: #3d4e67;
	background-color: #fff;
}
.form-control-file, .form-control-range {
	display: block;
	width: 100%;
}
.col-form-label {
	padding-top: calc(.375rem + 1px);
	padding-bottom: calc(.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.6;
}
.col-form-label-lg {
	padding-top: calc(.5rem + 1px);
	padding-bottom: calc(.5rem + 1px);
	font-size: 1.125rem;
	line-height: 1.44444444;
}
.col-form-label-sm {
	padding-top: calc(.25rem + 1px);
	padding-bottom: calc(.25rem + 1px);
	font-size: .875rem;
	line-height: 1.14285714;
}
.form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: .375rem;
	padding-bottom: .375rem;
	margin-bottom: 0;
	line-height: 1.6;
	color: #3d4e67;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
	&.form-control-lg, &.form-control-sm {
		padding-right: 0;
		padding-left: 0;
	}
}
.input-group-lg> {
	.form-control-plaintext.form-control {
		padding-right: 0;
		padding-left: 0;
	}
	.input-group-append>.form-control-plaintext {
		&.btn, &.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
	.input-group-prepend>.form-control-plaintext {
		&.btn, &.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
.input-group-sm> {
	.form-control-plaintext.form-control {
		padding-right: 0;
		padding-left: 0;
	}
	.input-group-append>.form-control-plaintext {
		&.btn, &.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
	.input-group-prepend>.form-control-plaintext {
		&.btn, &.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
.form-control-sm {
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.14285714;
	border-radius: 5px;
}
.input-group-sm> {
	.form-control {
		padding: .25rem .5rem;
		font-size: .875rem;
		line-height: 1.14285714;
		border-radius: 5px;
	}
	.input-group-append> {
		.btn, .input-group-text {
			padding: .25rem .5rem;
			font-size: .875rem;
			line-height: 1.14285714;
			border-radius: 5px;
		}
	}
	.input-group-prepend> {
		.btn, .input-group-text {
			padding: .25rem .5rem;
			font-size: .875rem;
			line-height: 1.14285714;
			border-radius: 5px;
		}
	}
	.input-group-append>select {
		&.btn:not([size]):not([multiple]), &.input-group-text:not([size]):not([multiple]) {
			height: calc(1.8125rem + 2px);
		}
	}
	.input-group-prepend>select {
		&.btn:not([size]):not([multiple]), &.input-group-text:not([size]):not([multiple]) {
			height: calc(1.8125rem + 2px);
		}
	}
	select.form-control:not([size]):not([multiple]) {
		height: calc(1.8125rem + 2px);
	}
}
select.form-control-sm:not([size]):not([multiple]) {
	height: calc(1.8125rem + 2px);
}
.form-control-lg {
	padding: .5rem 1rem;
	font-size: 1.125rem;
	line-height: 1.44444444;
	border-radius: 5px;
}
.input-group-lg> {
	.form-control {
		padding: .5rem 1rem;
		font-size: 1.125rem;
		line-height: 1.44444444;
		border-radius: 5px;
	}
	.input-group-append> {
		.btn, .input-group-text {
			padding: .5rem 1rem;
			font-size: 1.125rem;
			line-height: 1.44444444;
			border-radius: 5px;
		}
	}
	.input-group-prepend> {
		.btn, .input-group-text {
			padding: .5rem 1rem;
			font-size: 1.125rem;
			line-height: 1.44444444;
			border-radius: 5px;
		}
	}
	.input-group-append>select {
		&.btn:not([size]):not([multiple]), &.input-group-text:not([size]):not([multiple]) {
			height: calc(2.6875rem + 2px);
		}
	}
	.input-group-prepend>select {
		&.btn:not([size]):not([multiple]), &.input-group-text:not([size]):not([multiple]) {
			height: calc(2.6875rem + 2px);
		}
	}
	select.form-control:not([size]):not([multiple]) {
		height: calc(2.6875rem + 2px);
	}
}
select.form-control-lg:not([size]):not([multiple]) {
	height: calc(2.6875rem + 2px);
}
.form-text {
	display: block;
	margin-top: .25rem;
}
.form-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
	> {
		.col, [class*=col-] {
			padding-right: 5px;
			padding-left: 5px;
		}
	}
}
.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
}
.form-check-input {
	position: absolute;
	margin-top: .3rem;
	margin-left: -1.25rem;
	&:disabled~.form-check-label {
		color: #505662;
	}
}
.form-check-label {
	margin-bottom: 0;
}
.form-check-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 0;
	margin-right: .75rem;
	.form-check-input {
		position: static;
		margin-top: 0;
		margin-right: .3125rem;
		margin-left: 0;
	}
}
.valid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 87.5%;
	color: #3eb750;
}
.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(94, 186, 0, 0.8);
	border-radius: .2rem;
}
.custom-select.is-valid, .form-control.is-valid {
	border-color: #3eb750;
}
.was-validated {
	.custom-select:valid, .form-control:valid {
		border-color: #3eb750;
	}
}
.custom-select.is-valid:focus, .form-control.is-valid:focus {
	border-color: #3eb750;
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}
.was-validated {
	.custom-select:valid:focus, .form-control:valid:focus {
		border-color: #3eb750;
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
	}
}
.custom-select.is-valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.form-control.is-valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.was-validated {
	.custom-select:valid~ {
		.valid-feedback, .valid-tooltip {
			display: block;
		}
	}
	.form-control:valid~ {
		.valid-feedback, .valid-tooltip {
			display: block;
		}
	}
}
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
	color: #3eb750;
}
.form-check-input.is-valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.was-validated .form-check-input:valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.custom-control-input.is-valid~.custom-control-label, .was-validated .custom-control-input:valid~.custom-control-label {
	color: #3eb750;
}
.custom-control-input.is-valid~.custom-control-label::before, .was-validated .custom-control-input:valid~.custom-control-label::before {
	background-color: #9eff3b;
}
.custom-control-input.is-valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.was-validated .custom-control-input:valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.custom-control-input.is-valid:checked~.custom-control-label::before, .was-validated .custom-control-input:valid:checked~.custom-control-label::before {
	background-color: #78ed00;
}
.custom-control-input.is-valid:focus~.custom-control-label::before, .was-validated .custom-control-input:valid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px $border-color, 0 0 0 2px rgba(94, 186, 0, 0.25);
}
.custom-file-input.is-valid~.custom-file-label, .was-validated .custom-file-input:valid~.custom-file-label {
	border-color: #3eb750;
}
.custom-file-input.is-valid~.custom-file-label::before, .was-validated .custom-file-input:valid~.custom-file-label::before {
	border-color: inherit;
}
.custom-file-input.is-valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.was-validated .custom-file-input:valid~ {
	.valid-feedback, .valid-tooltip {
		display: block;
	}
}
.custom-file-input.is-valid:focus~.custom-file-label, .was-validated .custom-file-input:valid:focus~.custom-file-label {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}
.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 87.5%;
}
.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(205, 32, 31, 0.8);
	border-radius: .2rem;
}
.custom-select.is-invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.form-control.is-invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.was-validated {
	.custom-select:invalid~ {
		.invalid-feedback, .invalid-tooltip {
			display: block;
		}
	}
	.form-control:invalid~ {
		.invalid-feedback, .invalid-tooltip {
			display: block;
		}
	}
}
.form-check-input.is-invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.was-validated .form-check-input:invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.custom-control-input.is-invalid~.custom-control-label::before, .was-validated .custom-control-input:invalid~.custom-control-label::before {
	background-color: #ec8080;
}
.custom-control-input.is-invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.was-validated .custom-control-input:invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.custom-control-input.is-invalid:checked~.custom-control-label::before, .was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
	background-color: #e23e3d;
}
.custom-control-input.is-invalid:focus~.custom-control-label::before, .was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px $border-color, 0 0 0 2px rgba(205, 32, 31, 0.25);
}
.custom-file-input.is-invalid~.custom-file-label::before, .was-validated .custom-file-input:invalid~.custom-file-label::before {
	border-color: inherit;
}
.custom-file-input.is-invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.was-validated .custom-file-input:invalid~ {
	.invalid-feedback, .invalid-tooltip {
		display: block;
	}
}
.custom-file-input.is-invalid:focus~.custom-file-label, .was-validated .custom-file-input:invalid:focus~.custom-file-label {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}
.form-inline {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-ms-flex-align: center;
	align-items: center;
	.form-check {
		width: 100%;
	}
}
/*-------- Input Styles -------*/
