/*-------- DropDown -------*/

.dropdown, .dropleft, .dropright, .dropup {
	position: relative;
}
.dropdown-toggle {
	&::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: .255em;
		vertical-align: .255em;
		content: "";
		border-top: .3em solid;
		border-right: .3em solid transparent;
		border-bottom: 0;
		border-left: .3em solid transparent;
	}
	&:empty::after {
		margin-left: 0;
	}
}
.dropdown-menu-right {
	right: 0;
	left: auto;
}
.dropup {
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: .125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: .255em;
			vertical-align: .255em;
			content: "";
			border-top: 0;
			border-right: .3em solid transparent;
			border-bottom: .3em solid;
			border-left: .3em solid transparent;
		}
		&:empty::after {
			margin-left: 0;
		}
	}
}
.dropright {
	.dropdown-menu {
		top: 0;
		right: auto;
		left: 100%;
		margin-top: 0;
		margin-left: .125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: .255em;
			vertical-align: .255em;
			content: "";
			border-top: .3em solid transparent;
			border-right: 0;
			border-bottom: .3em solid transparent;
			border-left: .3em solid;
			vertical-align: 0;
		}
		&:empty::after {
			margin-left: 0;
		}
	}
}
.dropleft {
	.dropdown-menu {
		top: 0;
		right: 100%;
		left: auto;
		margin-top: 0;
		margin-right: .125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: .255em;
			vertical-align: .255em;
			content: "";
			display: none;
		}
		&::before {
			display: inline-block;
			width: 0;
			height: 0;
			margin-right: .255em;
			vertical-align: .255em;
			content: "";
			border-top: .3em solid transparent;
			border-right: .3em solid;
			border-bottom: .3em solid transparent;
			vertical-align: 0;
		}
		&:empty::after {
			margin-left: 0;
		}
	}
}
.dropdown-menu {
	&[x-placement^=bottom], &[x-placement^=left], &[x-placement^=right], &[x-placement^=top] {
		right: auto;
		bottom: auto;
	}
}
.dropdown-divider {
	height: 0;
	margin: .5rem 0;
	overflow: hidden;
	border-top: 1px solid $border-color;
}
.notifyimg {
	color: #fff;
	float: left;
	height: 40px;
	line-height: 43px;
	margin-right: 10px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
	border-radius: 50%;
}
.dropdown-item {
	display: block;
	width: 100%;
	padding: .5rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #3f3e50;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.app-selector ul {
	margin-bottom: 0;
}
.drop-icon-wrap {
	text-align: center;
	li {
		display: inline-block;
	}
	.drop-icon-item {
		display: inline-block;
		padding-bottom: 10px;
		padding-top: 10px;
		text-align: center;
		text-transform: capitalize;
		min-width: 70px;
		border-radius: 5px;
		position: relative;
		margin: 3px;
		background: #f5f5f9;
		.nav-unread.badge {
			right: 14px;
		}
	}
}
.block {
	display: block !important;
}
.drop-icon-item i {
	font-size: 20px;
	padding-bottom: 5px;
}
.drop-icon-wrap .drop-icon-item:hover {
	text-decoration: none;
	background-color: rgba(113, 103, 255, 0.085);
}
.dropdown-item.user {
	font-size: 16px;
	padding: 5px;
}
.user-semi-title {
	font-size: 12px;
}
.floating {
	animation: floating 3s ease infinite;
	will-change: transform;
}
.dropdown-item {
	&:focus, &:hover, &.active, &:active {
		text-decoration: none;
		background-color: $border-color;
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: transparent;
	}
}
.dropdown-menu.show {
	display: block;
	box-shadow: 0 5px 25px rgba(39, 55, 76, 0.2);
	font-size:13px;	
}
.header-right-icons{
	.dropdown-menu.show {
		margin-top:15px;
	}
}
.nav-item.show .dropdown-menu.dropdown-menu-arrow.show {
	top: -1px !important;
}
.dropdown-header {
	display: block;
	padding: .5rem 1.5rem;
	margin-bottom: 0;
	font-size: .875rem;
	color: #868e96;
	white-space: nowrap;
}
.dropdown-item-text {
	display: block;
	padding: .25rem 1.5rem;
	color: #cecde0;
}
btn.dropdown-toggle~.dropdown-menu {
	background-color: #f4f4f4 !important;
	background-color: #fff !important;
	border: 0 solid #4285f4 !important;
	box-shadow: 0 0 3px rgba(25, 25, 25, 0.3) !important;
	top: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
}
ul.dropdown-menu li.dropdown ul.dropdown-menu {
	background-color: #f4f4f4 !important;
	background-color: #fff !important;
	border: 0 solid #4285f4 !important;
	box-shadow: 0 0 3px rgba(25, 25, 25, 0.3) !important;
	top: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
	position: absolute !important;
	width: 100% !important;
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: .5rem 0;
	margin: .125rem 0 0;
	font-size: .9375rem;
	color: #3d4e67;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid $border-color;
	border-radius: 5px;
	box-shadow: 0 1px 2px 0 rgba(49, 45, 101, 0.05);
	min-width: 12rem;
	>li>a {
		display: block;
		padding: 6px 20px;
		clear: both;
		font-weight: 400;
		line-height: 1.428571429;
		color: $text-color;
		white-space: nowrap;
		&:focus, &:hover {
			text-decoration: none;
			background-color: $body-color;
		}
	}
	.divider {
		height: 1px;
		margin: 9px 0;
		overflow: hidden;
		background-color: $border-color;
	}
	.dropdown-plus-title {
		width: 100% !important;
		color: #cecde0 !important;
		padding: 6px 12px !important;
		font-weight: 500 !important;
		border: 0 solid $border-color !important;
		border-bottom-width: 1px !important;
		cursor: pointer !important;
	}
}
ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	line-height: 20px !important;
}
.btn.dropdown-toggle {
	&.btn-secondary~.dropdown-menu .dropdown-plus-title {
		border-color: #0575e6 !important;
	}
	&.btn-success~.dropdown-menu .dropdown-plus-title {
		border-color: #4cae4c !important;
	}
	&.btn-info~.dropdown-menu .dropdown-plus-title {
		border-color: #46b8da !important;
	}
	&.btn-warning~.dropdown-menu .dropdown-plus-title {
		border-color: #eea236 !important;
	}
	&.btn-danger~.dropdown-menu .dropdown-plus-title {
		border-color: #d43f3a !important;
	}
}
.dropdown-menu-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 15px;
	border-bottom: 1px solid $border-color;
}
.dropdown-demo .dropdown-menu {
	position: static;
	float: none;
}
.dropdown-menu-header label {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #cecde0;
}
.dropdown-media-list {
	.media {
		&:focus, &:hover {
			background-color: $border-color;
		}
		padding: 12px 15px;
	}
	img {
		border-radius: 100%;
	}
	.media-body {
		margin-left: 15px;
		> {
			div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				p {
					margin-bottom: 0;
					font-weight: 500;
					color: #cecde0;
					font-size: 14px;
				}
				span {
					font-size: 12px;
				}
			}
			p {
				font-size: 14px;
				margin-bottom: 0;
			}
		}
	}
}
.dropdown-list-footer {
	padding: 10px 15px;
	text-align: center;
	font-size: 12px;
	border-top: 1px solid $border-color;
}
.dropdown-media-list {
	padding: 0;
}
.dropdown-menu-arrow {
	&:before {
		position: absolute;
		top: -9px;
		left: 12px;
		display: inline-block;
		border-right: 9px solid transparent;
		border-bottom: 9px solid $border-color;
		border-left: 9px solid transparent;
		border-bottom-color: rgba(49, 45, 101, 0.2);
		content: '';
	}
	&:after {
		position: absolute;
		top: -9px;
		left: 12px;
		display: inline-block;
		border-right: 9px solid transparent;
		border-bottom: 9px solid #fff;
		border-left: 9px solid transparent;
		content: '';
	}
}
#sidebar .dropdown-menu {
	box-shadow: 0 1px 2px 0 rgba(49, 45, 101, 0.05);
	min-width: 12rem;
}
.dropdown-menu-arrow.dropdown-menu-right {
	&:after, &:before {
		left: auto;
		right: 12px;
	}
}
.dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	&:after {
		vertical-align: .155em;
	}
	&:empty:after {
		margin-left: 0;
	}
}
.dropdown-icon {
	margin-right: .5rem;
	margin-left: -.5rem;
	width: 1em;
	display: inline-block;
	text-align: center;
	vertical-align: -1px;
	font-size: 14px;
	font-weight: 600 !important;
}
/*-------- DropDown -------*/
