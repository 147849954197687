/*-------- Buttons -------*/
@import "../variables";
.btn {
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: .9375rem;
	line-height: 28px;
	border-radius: 5px;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	height: 40px;
	cursor: pointer;
	min-width: 2.375rem;
	&:focus, &:hover {
		text-decoration: none;
	}
	&.focus, &:focus {
		outline: 0;
		box-shadow: 0 0 0 2px rgba(194, 197, 199, 0.25);
	}
	&.disabled, &:disabled {
		opacity: 0.65;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
		&.active, &:active {
			background-image: none;
		}
	}
}
@media screen and (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}
a.btn.disabled, fieldset:disabled a.btn {
	pointer-events: none;
}
.btn-success {
	color: #fff;
	background-color: $color-success;
	border-color: $color-success;
	box-shadow: 0px 0px 12px 0px rgba(62, 183, 80, 0.35);
	&:hover {
		color: #fff;
		background-color: $btn-hover-success;
		border-color: $btn-hover-success;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(62, 183, 80, 0.1);
		background-color: $btn-hover-success;
		border-color: $btn-hover-success;
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: $btn-hover-success;
		border-color: $btn-hover-success;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $btn-hover-success;
			border-color: $btn-hover-success;
		}
	}
}
.show>.btn-success.dropdown-toggle {
	color: #fff;
	background-color: $btn-hover-success;
	border-color: $btn-hover-success;
}
.btn-success:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(62, 183, 80, 0.1);
		background-color: $btn-hover-success;
		border-color: $btn-hover-success;
	}
}
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(62, 183, 80, 0.1);
}


.btn-info {
	color: #fff;
	background-color: $color-info;
	border-color: $color-info;
	box-shadow: 0px 0px 12px 0px rgba(35, 188, 185, 0.35);
	&:hover {
		color: #fff;
		background-color: $btn-hover-info;
		border-color: $btn-hover-info;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(35, 188, 185, 0.1);
		background-color: $btn-hover-info;
		border-color: $btn-hover-info;
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: $btn-hover-info;
		border-color: $btn-hover-info;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $btn-hover-info;
			border-color: $btn-hover-info;
		}
	}
}
.show>.btn-info.dropdown-toggle {
	color: #fff;
	background-color: $btn-hover-info;
	border-color: $btn-hover-info;
}
.btn-info:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(35, 188, 185, 0.1);
		background-color: $btn-hover-info;
		border-color: $btn-hover-info;
	}
}
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(35, 188, 185, 0.1);
}

.btn-warning {
	color: #fff;
	background-color: $color-warning;
	border-color: $color-warning;
	box-shadow: 0px 0px 12px 0px rgba(255, 182, 9, 0.35);
	&:hover {
		color: #fff;
		background-color: $btn-hover-warning;
		border-color: $btn-hover-warning;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(255, 182, 9, 0.1);
		background-color: $btn-hover-warning;
		border-color: $btn-hover-warning;
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: $btn-hover-warning;
		border-color: $btn-hover-warning;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $btn-hover-warning;
			border-color: $btn-hover-warning;
		}
	}
}
.show>.btn-warning.dropdown-toggle {
	color: #fff;
	background-color: $btn-hover-warning;
	border-color: $btn-hover-warning;
}
.btn-warning:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(255, 182, 9, 0.1);
		background-color: $btn-hover-warning;
		border-color: $btn-hover-warning;
	}
}
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 182, 9, 0.1);
}

.btn-danger {
	color: #fff;
	background-color: $color-danger;
	border-color: $color-danger;
	box-shadow: 0px 0px 12px 0px rgba(249, 103, 104, 0.35);
	&:hover {
		color: #fff;
		background-color: $btn-hover-danger;
		border-color: $btn-hover-danger;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.1);
		background-color: $btn-hover-danger;
		border-color: $btn-hover-danger;
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: $btn-hover-danger;
		border-color: $btn-hover-danger;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $btn-hover-danger;
			border-color: $btn-hover-danger;
		}
	}
}
.show>.btn-danger.dropdown-toggle {
	color: #fff;
	background-color: $btn-hover-danger;
	border-color: $btn-hover-danger;
}
.btn-danger:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.1);
		background-color: $btn-hover-danger;
		border-color: $btn-hover-danger;
	}
}
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.1);
}
.btn-light {
	color: #3d4e67;
	background-color: $border-color;
	border-color: $border-color;
	&:hover {
		color: #3d4e67;
		background-color: #f9faff !important;
		border-color: $border-color;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
		background-color: #f9faff !important;
		border-color: $border-color;
	}
	&.disabled, &:disabled {
		color: #3d4e67;
		background-color: #f9faff;
		border-color: $border-color;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #3d4e67;
			background-color: #f9faff;
			border-color: #d3d9df;
		}
	}
}
.show>.btn-light.dropdown-toggle {
	color: #3d4e67;
	background-color: #f9faff;
	border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
}
.show>.btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-dark {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
	&:hover {
		color: #fff;
		background-color: #23272b;
		border-color: #1d2124;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #343a40;
		border-color: #343a40;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #1d2124;
			border-color: #171a1d;
		}
	}
}
.show>.btn-dark.dropdown-toggle {
	color: #fff;
	background-color: #1d2124;
	border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
}
.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}
.btn-outline-success {
	color: $color-success;
	background-color: transparent;
	background-image: none;
	border-color: $color-success;
	&:hover {
		color: #fff;
		background-color: $color-success;
		border-color: $color-success;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(62, 183, 80, 0.1);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $color-success;
			border-color: $color-success;
		}
	}
}
.show>.btn-outline-success.dropdown-toggle {
	color: #fff;
	background-color: $color-success;
	border-color: $color-success;
}
.btn-outline-success:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(62, 183, 80, 0.1);
	}
}
.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(62, 183, 80, 0.1);
}

.btn-outline-info {
	color: $color-info;
	background-color: transparent;
	background-image: none;
	border-color: $color-info;
	&:hover {
		color: #fff;
		background-color: $color-info;
		border-color: $color-info;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(35, 188, 185, 0.1);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $color-info;
			border-color: $color-info;
		}
	}
}
.show>.btn-outline-info.dropdown-toggle {
	color: #fff;
	background-color: $color-info;
	border-color: $color-info;
}
.btn-outline-info:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(35, 188, 185, 0.1);
	}
}
.show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(35, 188, 185, 0.1);
}

.btn-outline-warning {
	color: $color-warning;
	background-color: transparent;
	background-image: none;
	border-color: $color-warning;
	&:hover {
		color: #fff;
		background-color: $color-warning;
		border-color: $color-warning;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(255, 182, 9, 0.1);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $color-warning;
			border-color: $color-warning;
		}
	}
}
.show>.btn-outline-warning.dropdown-toggle {
	color: #fff;
	background-color: $color-warning;
	border-color: $color-warning;
}
.btn-outline-warning:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(255, 182, 9, 0.1);
	}
}
.show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 182, 9, 0.1);
}


.btn-outline-danger {
	color: $color-danger;
	background-color: transparent;
	background-image: none;
	border-color: $color-danger;
	&:hover {
		color: #fff;
		background-color: $color-danger;
		border-color: $color-danger;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.1);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $color-danger;
			border-color: $color-danger;
		}
	}
}
.show>.btn-outline-danger.dropdown-toggle {
	color: #fff;
	background-color: $color-danger;
	border-color: $color-danger;
}
.btn-outline-danger:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.1);
	}
}
.show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(249, 103, 104, 0.1);
}

.btn-outline-light {
	color: #f8f9fa;
	background-color: transparent;
	background-image: none;
	border-color: #f8f9fa;
	&:hover {
		color: #3d4e67;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
	&.disabled, &:disabled {
		color: #f8f9fa;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #3d4e67;
			background-color: #f8f9fa;
			border-color: #f8f9fa;
		}
	}
}
.show>.btn-outline-light.dropdown-toggle {
	color: #3d4e67;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
}
.show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-outline-dark {
	color: #343a40;
	background-color: transparent;
	background-image: none;
	border-color: #343a40;
	&:hover {
		color: #fff;
		background-color: #343a40;
		border-color: #343a40;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
	&.disabled, &:disabled {
		color: #343a40;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #343a40;
			border-color: #343a40;
		}
	}
}
.show>.btn-outline-dark.dropdown-toggle {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
}
.show>.btn-outline-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}
.btn-link {
	font-weight: 400;
	background-color: transparent;
	&:hover {
		color: $text-color;
		text-decoration: none;
		background-color: transparent;
		border-color: transparent;
	}
	&.focus, &:focus {
		text-decoration: none;
		border-color: transparent;
		box-shadow: none;
	}
	&.disabled, &:disabled {
		color: #868e96;
		pointer-events: none;
	}
}
.btn-group-lg>.btn, .btn-lg {
	padding: .5rem 1rem;
	font-size: 1.125rem;
	line-height: 28px;
	border-radius: 5px;
	height: 46px;
	font-weight: 500 !important;
}
.btn-group-sm>.btn, .btn-sm {
	padding: .25rem .5rem;
	font-size: .875rem;
	height: 30px;
	line-height: 22px !important;
	border-radius: 3px;
}
.btn-block {
	display: block;
	width: 100%;
	+.btn-block {
		margin-top: 0.5rem;
	}
}
input {
	&[type=button].btn-block, &[type=reset].btn-block, &[type=submit].btn-block {
		width: 100%;
	}
}
.fade {
	transition: opacity 0.15s linear;
	&:not(.show) {
		opacity: 0;
	}
}
@media screen and (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}
.collapse:not(.show) {
	display: none;
	background: #fff;
}
.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}
.btn-icon {
	padding-left: .5rem;
	padding-right: .5rem;
	text-align: center;
}
.btn.btn-app {
	position: relative;
	padding: 15px 5px;
	margin: 0 0 10px 10px;
	min-width: 80px;
	height: 68px;
	box-shadow: none;
	border-radius: 0;
	text-align: center;
	color: #a7b4c9;
	border: 1px solid $border-color;
	background-color: #f9faff;
	font-size: 12px;
	> {
		.fa, .glyphicon, .ion {
			font-size: 20px;
			display: block;
		}
	}
	&:hover {
		background: #f9faff;
		color: #3f5068;
		border-color: $border-color;
	}
	&:active, &:focus {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}
	>.badge {
		position: absolute;
		top: -3px;
		right: -10px;
		font-weight: 400;
	}
}
.btn-pill {
	border-radius: 10rem;
	padding-left: 1.5em;
	padding-right: 1.5em;
}
.btn-square {
	border-radius: 0;
}
.btn-facebook {
	color: #fff;
	background-color: #3b5998;
	border-color: #3b5998;
	&:hover, &.active {
		color: #fff;
		background-color: #3b5998;
		border-color: #3b5998;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #3b5998;
		border-color: #3b5998;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #3b5998;
			border-color: #3b5998;
		}
	}
}
.show>.btn-facebook.dropdown-toggle {
	color: #fff;
	background-color: #3b5998;
	border-color: #3b5998;
}
.btn-facebook:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
	}
}
.show>.btn-facebook.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
}
.btn-twitter {
	color: #fff;
	background-color: #1da1f2;
	border-color: #1da1f2;
	&:hover {
		color: #fff;
		background-color: #1da1f2;
		border-color: #1da1f2;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #1da1f2;
		border-color: #1da1f2;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #1da1f2;
			border-color: #1da1f2;
		}
	}
}
.show>.btn-twitter.dropdown-toggle {
	color: #fff;
	background-color: #1da1f2;
	border-color: #1da1f2;
}
.btn-twitter:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
	}
}
.show>.btn-twitter.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
}
.btn-google {
	color: #fff;
	background-color: #dd4b39;
	border-color: #dd4b39;
	&.active, &:hover {
		color: #fff;
		background-color: #dd4b39;
		border-color: #dd4b39;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(221, 75, 57, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #dd4b39;
		border-color: #dd4b39;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #dd4b39;
			border-color: #dd4b39;
		}
	}
}
.show>.btn-google.dropdown-toggle {
	color: #fff;
	background-color: #dd4b39;
	border-color: #dd4b39;
}
.btn-google:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(221, 75, 57, 0.5);
	}
}
.show>.btn-google.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(221, 75, 57, 0.5);
}
.btn-youtube {
	color: #fff;
	background-color: red;
	border-color: red;
	&:hover {
		color: #fff;
		background-color: #d90000;
		border-color: #c00;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: red;
		border-color: red;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #c00;
			border-color: #bf0000;
		}
	}
}
.show>.btn-youtube.dropdown-toggle {
	color: #fff;
	background-color: #c00;
	border-color: #bf0000;
}
.btn-youtube:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
	}
}
.show>.btn-youtube.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
}
.btn-vimeo {
	color: #fff;
	background-color: #1ab7ea;
	border-color: #1ab7ea;
	&:hover {
		color: #fff;
		background-color: #139ecb;
		border-color: #1295bf;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #1ab7ea;
		border-color: #1ab7ea;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #1295bf;
			border-color: #108cb4;
		}
	}
}
.show>.btn-vimeo.dropdown-toggle {
	color: #fff;
	background-color: #1295bf;
	border-color: #108cb4;
}
.btn-vimeo:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
	}
}
.show>.btn-vimeo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
}
.btn-dribbble {
	color: #fff;
	background-color: #ea4c89;
	border-color: #ea4c89;
	&:hover {
		color: #fff;
		background-color: #e62a72;
		border-color: #e51e6b;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #ea4c89;
		border-color: #ea4c89;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #e51e6b;
			border-color: #dc1a65;
		}
	}
}
.show>.btn-dribbble.dropdown-toggle {
	color: #fff;
	background-color: #e51e6b;
	border-color: #dc1a65;
}
.btn-dribbble:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
	}
}
.show>.btn-dribbble.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
}
.btn-github {
	color: #fff;
	background-color: #181717;
	border-color: #181717;
	&:hover {
		color: #fff;
		background-color: #040404;
		border-color: #000;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #181717;
		border-color: #181717;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #000;
			border-color: #000;
		}
	}
}
.show>.btn-github.dropdown-toggle {
	color: #fff;
	background-color: #000;
	border-color: #000;
}
.btn-github:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
	}
}
.show>.btn-github.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
}
.btn-instagram {
	color: #fff;
	background-color: #e4405f;
	border-color: #e4405f;
	&:hover {
		color: #fff;
		background-color: #de1f44;
		border-color: #d31e40;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #e4405f;
		border-color: #e4405f;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #d31e40;
			border-color: #c81c3d;
		}
	}
}
.show>.btn-instagram.dropdown-toggle {
	color: #fff;
	background-color: #d31e40;
	border-color: #c81c3d;
}
.btn-instagram:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
	}
}
.show>.btn-instagram.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
}
.btn-pinterest {
	color: #fff;
	background-color: #bd081c;
	border-color: #bd081c;
	&:hover {
		color: #fff;
		background-color: #980617;
		border-color: #8c0615;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #bd081c;
		border-color: #bd081c;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #8c0615;
			border-color: #800513;
		}
	}
}
.show>.btn-pinterest.dropdown-toggle {
	color: #fff;
	background-color: #8c0615;
	border-color: #800513;
}
.btn-pinterest:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
	}
}
.show>.btn-pinterest.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
}
.btn-vk {
	color: #fff;
	background-color: #6383a8;
	border-color: #6383a8;
	&:hover {
		color: #fff;
		background-color: #527093;
		border-color: #4d6a8b;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #6383a8;
		border-color: #6383a8;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #4d6a8b;
			border-color: #496482;
		}
	}
}
.show>.btn-vk.dropdown-toggle {
	color: #fff;
	background-color: #4d6a8b;
	border-color: #496482;
}
.btn-vk:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
	}
}
.show>.btn-vk.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
}
.btn-rss {
	color: #fff;
	background-color: orange;
	border-color: orange;
	&:hover {
		color: #fff;
		background-color: #d98c00;
		border-color: #cc8400;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: orange;
		border-color: orange;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #cc8400;
			border-color: #bf7c00;
		}
	}
}
.show>.btn-rss.dropdown-toggle {
	color: #fff;
	background-color: #cc8400;
	border-color: #bf7c00;
}
.btn-rss:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
	}
}
.show>.btn-rss.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}
.btn-flickr {
	color: #fff;
	background-color: #0063dc;
	border-color: #0063dc;
	&:hover {
		color: #fff;
		background-color: #0052b6;
		border-color: #004ca9;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #0063dc;
		border-color: #0063dc;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #004ca9;
			border-color: #00469c;
		}
	}
}
.show>.btn-flickr.dropdown-toggle {
	color: #fff;
	background-color: #004ca9;
	border-color: #00469c;
}
.btn-flickr:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
	}
}
.show>.btn-flickr.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
}
.btn-bitbucket {
	color: #fff;
	background-color: #0052cc;
	border-color: #0052cc;
	&:hover {
		color: #fff;
		background-color: #0043a6;
		border-color: #003e99;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #0052cc;
		border-color: #0052cc;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #003e99;
			border-color: #00388c;
		}
	}
}
.show>.btn-bitbucket.dropdown-toggle {
	color: #fff;
	background-color: #003e99;
	border-color: #00388c;
}
.btn-bitbucket:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
	}
}
.show>.btn-bitbucket.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
}
.btn-blue {
	color: #fff;
	background-color: $color-blue;
	border-color: $color-blue;
	&:hover {
		color: #fff;
		background-color: $btn-hover-blue;
		border-color: $btn-hover-blue;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: $color-blue;
		border-color: $color-blue;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $btn-hover-blue;
			border-color: $btn-hover-blue;
		}
	}
}
.show>.btn-blue.dropdown-toggle {
	color: #fff;
	background-color: $btn-hover-blue;
	border-color: $btn-hover-blue;
}
.btn-blue:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
	}
}
.show>.btn-blue.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
}
.btn-indigo {
	color: #fff;
	background-color: $color-indigo;
	border-color: $color-indigo;
	&:hover {
		color: #fff;
		background-color: $btn-hover-indigo;
		border-color: $btn-hover-indigo;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: $color-indigo;
		border-color: $color-indigo;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: $btn-hover-indigo;
			border-color: $btn-hover-indigo;
		}
	}
}
.show>.btn-indigo.dropdown-toggle {
	color: #fff;
	background-color: $btn-hover-indigo;
	border-color: $btn-hover-indigo;
}
.btn-indigo:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
	}
}
.show>.btn-indigo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
}
.btn-purple {
	color: #fff;
	background-color: #604dd8;
	border-color: #604dd8;
	&:hover {
		color: #fff;
		background-color: #923ce6;
		border-color: #8c31e4;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #604dd8;
		border-color: #604dd8;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #8c31e4;
			border-color: #8526e3;
		}
	}
}
.show>.btn-purple.dropdown-toggle {
	color: #fff;
	background-color: #8c31e4;
	border-color: #8526e3;
}
.btn-purple:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
	}
}
.show>.btn-purple.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
}
.btn-pink {
	color: #fff;
	background-color: #ec296b;
	border-color: #ec296b;
	&:hover {
		color: #fff;
		background-color: #e61470;
		border-color: #e61470;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #ec296b;
		border-color: #ec296b;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #e61470;
			border-color: #e61470;
		}
	}
}
.show>.btn-pink.dropdown-toggle {
	color: #fff;
	background-color: #e61470;
	border-color: #e61470;
}
.btn-pink:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
	}
}
.show>.btn-pink.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
}
.btn-red {
	color: #fff;
	background-color: #fe6567;
	border-color: #fe6567;
	&:hover {
		color: #fff;
		background-color: #b3160b;
		border-color: #b3160b;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #fe6567;
		border-color: #fe6567;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #b3160b;
			border-color: #b3160b;
		}
	}
}
.show>.btn-red.dropdown-toggle {
	color: #fff;
	background-color: #b3160b;
	border-color: #b3160b;
}
.btn-red:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
	}
}
.show>.btn-red.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
}
.btn-orange {
	color: #fff;
	background-color: #e67605;
	border-color: #e67605;
	&:hover {
		color: #fff;
		background-color: #fd811e;
		border-color: #fc7a12;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #e67605;
		border-color: #e67605;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #fc7a12;
			border-color: #fc7305;
		}
	}
}
.show>.btn-orange.dropdown-toggle {
	color: #fff;
	background-color: #fc7a12;
	border-color: #fc7305;
}
.btn-orange:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
	}
}
.show>.btn-orange.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
}
.btn-yellow {
	color: #fff;
	background-color: #ffb609;
	border-color: #ffb609;
	&:hover {
		color: #fff;
		background-color: #fa3;
		border-color: #fa3;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #ffb609;
		border-color: #ffb609;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #fa3;
			border-color: #b6940b;
		}
	}
}
.show>.btn-yellow.dropdown-toggle {
	color: #fff;
	background-color: #fa3;
	border-color: #b6940b;
}
.btn-yellow:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
	}
}
.show>.btn-yellow.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
}
.btn-green {
	color: #fff;
	background-color: #3eb750;
	border-color: #3eb750;
	&:hover {
		color: #fff;
		background-color: #3adfab;
		border-color: #3adfab;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #3eb750;
		border-color: #3eb750;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #3adfab;
			border-color: #3adfab;
		}
	}
}
.show>.btn-green.dropdown-toggle {
	color: #fff;
	background-color: #3adfab;
	border-color: #3adfab;
}
.btn-green:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
	}
}
.show>.btn-green.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
}
.btn-teal {
	color: #fff;
	background-color: #05e6e6;
	border-color: #05e6e6;
	&:hover {
		color: #fff;
		background-color: #05fafa;
		border-color: #05fafa;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #05e6e6;
		border-color: #05e6e6;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #05fafa;
			border-color: #05fafa;
		}
	}
}
.show>.btn-teal.dropdown-toggle {
	color: #fff;
	background-color: #05fafa;
	border-color: #05fafa;
}
.btn-teal:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
	}
}
.show>.btn-teal.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
}
.btn-cyan {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
	&:hover {
		color: #fff;
		background-color: #138496;
		border-color: #117a8b;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #17a2b8;
		border-color: #17a2b8;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #117a8b;
			border-color: #10707f;
		}
	}
}
.show>.btn-cyan.dropdown-toggle {
	color: #fff;
	background-color: #117a8b;
	border-color: #10707f;
}
.btn-cyan:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
	}
}
.show>.btn-cyan.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
}
.btn-white {
	color: #3d4e67;
	background-color: #fff;
	border-color: $border-color;
	&:hover {
		color: #3d4e67;
		background-color: #e9ecfb;
		border-color: #e9ecfb;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
	}
	&.disabled, &:disabled {
		color: #3d4e67;
		background-color: #fff;
		border-color: #fff;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #3d4e67;
			background-color: #e9ecfb;
			border-color: #e9ecfb;
		}
	}
}
.show>.btn-white.dropdown-toggle {
	color: #3d4e67;
	background-color: #e9ecfb;
	border-color: #e9ecfb;
}
.btn-white:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
	}
}
.show>.btn-white.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}
.btn-gray {
	color: #fff;
	background-color: #868e96;
	border-color: #868e96;
	&:hover {
		color: #fff;
		background-color: #727b84;
		border-color: #605e7e;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #868e96;
		border-color: #868e96;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #605e7e;
			border-color: #666e76;
		}
	}
}
.show>.btn-gray.dropdown-toggle {
	color: #fff;
	background-color: #605e7e;
	border-color: #666e76;
}
.btn-gray:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}
}
.show>.btn-gray.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
}
.btn-gray-dark {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
	&:hover {
		color: #fff;
		background-color: #23272b;
		border-color: #1d2124;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #343a40;
		border-color: #343a40;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #1d2124;
			border-color: #171a1d;
		}
	}
}
.show>.btn-gray-dark.dropdown-toggle {
	color: #fff;
	background-color: #1d2124;
	border-color: #171a1d;
}
.btn-gray-dark:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
	}
}
.show>.btn-gray-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
}
.btn-azure {
	color: #fff;
	background-color: #00d6e6;
	border-color: #00d6e6;
	&:hover {
		color: #fff;
		background-color: #219af0;
		border-color: #1594ef;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #00d6e6;
		border-color: #00d6e6;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #1594ef;
			border-color: #108ee7;
		}
	}
}
.show>.btn-azure.dropdown-toggle {
	color: #fff;
	background-color: #1594ef;
	border-color: #108ee7;
}
.btn-azure:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
	}
}
.show>.btn-azure.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
}
.btn-lime {
	color: #fff;
	background-color: #63e600;
	border-color: #63e600;
	&:hover {
		color: #fff;
		background-color: #69b829;
		border-color: #63ad27;
	}
	&.focus, &:focus {
		box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
	}
	&.disabled, &:disabled {
		color: #fff;
		background-color: #63e600;
		border-color: #63e600;
	}
	&:not(:disabled):not(.disabled) {
		&.active, &:active {
			color: #fff;
			background-color: #63ad27;
			border-color: #5da324;
		}
	}
}
.show>.btn-lime.dropdown-toggle {
	color: #fff;
	background-color: #63ad27;
	border-color: #5da324;
}
.btn-lime:not(:disabled):not(.disabled) {
	&.active:focus, &:active:focus {
		box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
	}
}
.show>.btn-lime.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
}
.btn-option {
	background: 0 0;
	color: #a7b4c9;
	&:hover {
		color: #5f6877;
	}
	&:focus {
		box-shadow: none;
		color: #5f6877;
	}
}
.btn-list {
	margin-bottom: -.5rem;
	font-size: 0;
	> {
		.btn, .dropdown {
			margin-bottom: 0.5rem;
		}
		.btn:not(:last-child), .dropdown:not(:last-child) {
			margin-right: 0.5rem;
		}
	}
}
.btn-loading {
	color: transparent !important;
	pointer-events: none;
	position: relative;
	&:after {
		content: '';
		-webkit-animation: loader .5s infinite linear;
		animation: loader .5s infinite linear;
		border: 2px solid #fff;
		border-radius: 50%;
		border-right-color: transparent !important;
		border-top-color: transparent !important;
		display: block;
		height: 1.4em;
		width: 1.4em;
		position: absolute;
		left: calc(50% - (1.4em / 2));
		top: calc(50% - (1.4em / 2));
		-webkit-transform-origin: center;
		transform-origin: center;
		position: absolute !important;
	}
}
.btn-group-sm>.btn-loading.btn:after {
	height: 1em;
	width: 1em;
	left: calc(50% - (1em / 2));
	top: calc(50% - (1em / 2));
}
.btn-loading {
	&.btn-sm:after {
		height: 1em;
		width: 1em;
		left: calc(50% - (1em / 2));
		top: calc(50% - (1em / 2));
	}
	&.btn-primary:after {
		border-color: #fff;
	}
}
.btn-send {
	background: none repeat scroll 0 0 #00a8b3;
	color: #fff;
	&:hover {
		background: none repeat scroll 0 0 #009da7;
	}
}
/*-------- Group Buttons -------*/

.btn-group {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
}
.btn-group-vertical {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
	>.btn {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
	}
}
.btn-group>.btn {
	position: relative;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
}
.btn-group-vertical>.btn:hover, .btn-group>.btn:hover {
	z-index: 1;
}
.btn-group-vertical>.btn {
	&.active, &:active, &:focus {
		z-index: 1;
	}
}
.btn-group {
	>.btn {
		&.active, &:active, &:focus {
			z-index: 1;
		}
	}
	.btn+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
	.btn-group+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
}
.btn-group-vertical {
	.btn+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
	.btn-group+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
}
.btn-toolbar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
	.input-group {
		width: auto;
	}
}
.btn-group> {
	.btn:first-child {
		margin-left: 0;
	}
	.btn-group:not(:last-child)>.btn, .btn:not(:last-child):not(.dropdown-toggle) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.btn-group:not(:first-child)>.btn, .btn:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
.dropdown-toggle-split {
	padding-right: .5625rem;
	padding-left: .5625rem;
	&::after {
		margin-left: 0;
	}
}
.dropright .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
	margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}
.btn-group-sm>.btn+.dropdown-toggle-split, .btn-sm+.dropdown-toggle-split {
	padding-right: .375rem;
	padding-left: .375rem;
}
.btn-group-lg>.btn+.dropdown-toggle-split, .btn-lg+.dropdown-toggle-split {
	padding-right: .75rem;
	padding-left: .75rem;
}
.btn-group-vertical {
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
	.btn, .btn-group {
		width: 100%;
	}
	> {
		.btn+ {
			.btn, .btn-group {
				margin-top: -1px;
				margin-left: 0;
			}
		}
		.btn-group {
			+ {
				.btn, .btn-group {
					margin-top: -1px;
					margin-left: 0;
				}
			}
			&:not(:last-child)>.btn {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		.btn:not(:last-child):not(.dropdown-toggle) {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		.btn-group:not(:first-child)>.btn, .btn:not(:first-child) {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}
.btn-group-toggle> {
	.btn, .btn-group>.btn {
		margin-bottom: 0;
	}
	.btn input {
		&[type=checkbox], &[type=radio] {
			position: absolute;
			clip: inherit;
			pointer-events: none;
		}
	}
	.btn-group>.btn input {
		&[type=checkbox], &[type=radio] {
			position: absolute;
			clip: inherit;
			pointer-events: none;
		}
	}
}
.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
	> {
		.custom-file, .custom-select, .form-control {
			position: relative;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			width: 1%;
			margin-bottom: 0;
		}
		.custom-file:focus, .custom-select:focus, .form-control:focus {
			z-index: 3;
		}
		.custom-file+ {
			.custom-file, .custom-select, .form-control {
				margin-left: -1px;
			}
		}
		.custom-select+ {
			.custom-file, .custom-select, .form-control {
				margin-left: -1px;
			}
		}
		.form-control+ {
			.custom-file, .custom-select, .form-control {
				margin-left: -1px;
			}
		}
		.custom-select:not(:last-child), .form-control:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.custom-select:not(:first-child), .form-control:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		.custom-file {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			&:not(:last-child) .custom-file-label {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				&::after {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
			&:not(:first-child) .custom-file-label {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				&::after {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}
.input-group-append, .input-group-prepend {
	display: -ms-flexbox;
	display: flex;
}
.input-group-append .btn, .input-group-prepend .btn {
	position: relative;
	z-index: 2;
}
.input-group-append {
	.btn+ {
		.btn, .input-group-text {
			margin-left: -1px;
		}
	}
	.input-group-text+ {
		.btn, .input-group-text {
			margin-left: -1px;
		}
	}
}
.input-group-prepend {
	.btn+ {
		.btn, .input-group-text {
			margin-left: -1px;
		}
	}
	.input-group-text+ {
		.btn, .input-group-text {
			margin-left: -1px;
		}
	}
	margin-right: -1px;
}
.input-group-append {
	margin-left: -1px;
}
.input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: .375rem .75rem;
	margin-bottom: 0;
	font-size: .9375rem;
	font-weight: 400;
	line-height: 1.6;
	color: #3d4e67;
	text-align: center;
	white-space: nowrap;
	background-color: $border-color;
	border: 1px solid $border-color;
	border-radius: 5px;
	input {
		&[type=checkbox], &[type=radio] {
			margin-top: 0;
		}
	}
}
.input-group> {
	.input-group-append {
		&:last-child> {
			.btn:not(:last-child):not(.dropdown-toggle), .input-group-text:not(:last-child) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:not(:last-child)> {
			.btn, .input-group-text {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
	.input-group-prepend> {
		.btn, .input-group-text {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	.input-group-append> {
		.btn, .input-group-text {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.input-group-prepend {
		&:first-child> {
			.btn:not(:first-child), .input-group-text:not(:first-child) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&:not(:first-child)> {
			.btn, .input-group-text {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
}
.btn-group-toolbar{
	.btn-group{
		.btn{
			border-radius:0px;
		}
		&:first-child{
			.btn:first-child{
				border-radius:5px 0 0 5px;;
			}
		}
		&:last-child{
			.btn:last-child{
				border-radius: 0 5px 5px 0;
			}
		}
	}
}
/*-------- Buttons -------*/
