/*------ Owl Carousel -------*/

.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		touch-action: manipulation;
		-moz-backface-visibility: hidden;
		/* fix firefox animation glitch */
		&:after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		}
	}
	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		/* fix for flashing background */
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	.owl-wrapper {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
	}
	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		img {
			display: block;
		}
	}
	.owl-carousel:not(.owl-loaded){ 
    opacity: 0; 
}
	.owl-nav.disabled, .owl-dots.disabled {
		display: none;
	}
	.owl-nav {
		.owl-prev, .owl-next {
			cursor: pointer;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
	.owl-dot {
		cursor: pointer;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.owl-nav button {
		&.owl-prev, &.owl-next {
			background: rgba(105, 99, 255, 0.3);
			opacity: 7;
			color: inherit;
			border: none;
			padding: 0 !important;
			font: inherit;
		}
	}
	button.owl-dot {
		background: rgba(105, 99, 255, 0.3);
		opacity: 7;
		color: inherit;
		border: none;
		padding: 0 !important;
		font: inherit;
	}
	&.owl-loaded {
		display: block;
	}
	&.owl-loading {
		opacity: 0;
		display: block;
	}
	&.owl-hidden {
		opacity: 0;
	}
	&.owl-refresh .owl-item {
		visibility: hidden;
	}
	&.owl-drag .owl-item {
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	&.owl-grab {
		cursor: move;
		cursor: grab;
	}
	&.owl-rtl {
		direction: rtl;
		.owl-item {
			float: right;
		}
	}
}
/* No Js */

.no-js .owl-carousel {
	display: block;
}
/*
 *  Owl Carousel - Animate Plugin
 */

.owl-carousel {
	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both;
	}
	.owl-animated-in {
		z-index: 0;
	}
	.owl-animated-out {
		z-index: 1;
	}
	.fadeOut {
		animation-name: fadeOut;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
	transition: height 500ms ease-in-out;
}
/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel {
	.owl-item {
		/**
    		This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
    		calculation of the height of the owl-item that breaks page layouts
    	 */
		.owl-lazy {
			opacity: 0;
			transition: opacity 400ms ease;
			&[src^=""], &:not([src]) {
				max-height: 0;
			}
		}
		img.owl-lazy {
			transform-style: preserve-3d;
		}
	}
	.owl-video-wrapper {
		position: relative;
		height: 100%;
		background: #000;
	}
	.owl-video-play-icon {
		position: absolute;
		height: 80px;
		width: 80px;
		left: 50%;
		top: 50%;
		margin-left: -40px;
		margin-top: -40px;
		background: url("owl.video.play.png") no-repeat;
		cursor: pointer;
		z-index: 1;
		-webkit-backface-visibility: hidden;
		transition: transform 100ms ease;
		&:hover {
			-ms-transform: scale(1.3, 1.3);
			transform: scale(1.3, 1.3);
		}
	}
	.owl-video-playing {
		.owl-video-tn, .owl-video-play-icon {
			display: none;
		}
	}
	.owl-video-tn {
		opacity: 0;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: opacity 400ms ease;
	}
	.owl-video-frame {
		position: relative;
		z-index: 1;
		height: 100%;
		width: 100%;
	}
}
/*
 * 	Owl Carousel - Video Plugin
 */

.owl-controls {
	.owl-page {
		width: 65px;
		height: 65px;
		border-radius: 50%;
		border: 4px solid #d9d6e0;
		background: url("../images/faces/female/6.jpg") no-repeat;
		background-size: cover;
		transition: all 0.3s ease-in-out 0s;
		&:nth-child(2) {
			background: url("../images/faces/male/13.jpg") no-repeat;
			background-size: cover;
		}
		&:nth-child(3) {
			background: url("../images/faces/female/15.jpg") no-repeat;
			background-size: cover;
		}
	}
	&.clickable .owl-page:hover {
		opacity: 0.5;
	}
	.owl-page {
		&.active {
			border-color: #ec296b;
			opacity: 1;
			&:hover {
				border-color: #ec296b;
				opacity: 1;
			}
		}
		display: inline-block;
		zoom: 1;
	}
	margin-top: 40px;
	text-align: center;
}
.testimonia {
	.post {
		display: block;
		font-size: 15px;
		color: rgba(255, 255, 255, 0.7);
	}
	.title {
		font-size: 24px;
		font-weight: 600;
		color: #fff;
		text-transform: capitalize;
		margin: 0;
		margin-bottom: 1px;
	}
	.rating-stars .rating-stars-container .rating-star {
		&.sm {
			display: inline-block;
			font-size: 14px;
			color: rgba(255, 255, 255, 0.3);
			cursor: pointer;
			padding: 1px;
			padding-right: 5px;
			padding-left: 5px;
		}
		&.is--active {
			color: #ffb609 !important;
		}
	}
}
.rating-stars .rating-stars-container .rating-star.is--hover {
	color: #ffb609 !important;
}
.owl-carousel.owl-carousel-icons.owl-loaded .owl-item {
	-webkit-backface-visibility: hidden !important;
	-webkit-transform: translateZ(0) scale(1.0, 1.0)  !important;
	backface-visibility: hidden !important;
	transform: translateZ(0) scale(1.0, 1.0)  !important;
	transform: translate3d(0, 0, 0)  !important;
}
/*------ Owl Carousel -------*/