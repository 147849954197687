/*-------- Tooltip & Popover-------*/
.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	margin: 0;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .875rem;
	word-wrap: break-word;
	opacity: 0;
	&.show {
		opacity: .9;
	}
	.arrow {
		position: absolute;
		display: block;
		width: .8rem;
		height: .4rem;
		&::before {
			position: absolute;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}
.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
	padding: .4rem 0;
}
.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
	bottom: 0;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
	top: 0;
	border-width: .4rem .4rem 0;
	border-top-color: #000;
}
.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
	padding: 0 .4rem;
}
.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
	left: 0;
	width: .4rem;
	height: .8rem;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
	right: 0;
	border-width: .4rem .4rem .4rem 0;
	border-right-color: #000;
}
.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
	padding: .4rem 0;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
	top: 0;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
	bottom: 0;
	border-width: 0 .4rem .4rem;
	border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
	padding: 0 .4rem;
}
.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
	right: 0;
	width: .4rem;
	height: .8rem;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
	left: 0;
	border-width: .4rem 0 .4rem .4rem;
	border-left-color: #000;
}
.tooltip-inner {
	max-width: 200px;
	padding: .25rem .5rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 5px;
}
.popover {
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: .875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #dee3eb;
	border-radius: 5px;
	-webkit-filter: drop-shadow(0 1px 3px rgba(49, 45, 101, 0.1));
	filter: drop-shadow(0 1px 3px rgba(49, 45, 101, 0.1));
	.arrow {
		position: absolute;
		display: block;
		width: .5rem;
		height: .5rem;
		margin: 0 3px;
		margin-left: calc(.25rem + -5px);
		&::after, &::before {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}
.bs-popover-auto[x-placement^=top], .bs-popover-top {
	margin-bottom: .5rem;
}
.bs-popover-auto[x-placement^=top] .arrow, .bs-popover-top .arrow {
	bottom: calc((.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^=top] .arrow {
	&::after, &::before {
		border-width: .5rem .25rem 0;
	}
}
.bs-popover-top .arrow {
	&::after, &::before {
		border-width: .5rem .25rem 0;
	}
}
.bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::before {
	bottom: 0;
	border-top-color: rgba(49, 45, 101, 0.25);
}
.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after {
	bottom: 1px;
	border-top-color: #fff;
}
.bs-popover-auto[x-placement^=right], .bs-popover-right {
	margin-left: .5rem;
}
.bs-popover-auto[x-placement^=right] .arrow, .bs-popover-right .arrow {
	left: calc((.5rem + 1px) * -1);
	width: .5rem;
	height: .5rem;
	margin: 3px 0;
}
.bs-popover-auto[x-placement^=right] .arrow {
	&::after, &::before {
		border-width: .25rem .5rem .25rem 0;
	}
}
.bs-popover-right .arrow {
	&::after, &::before {
		border-width: .25rem .5rem .25rem 0;
	}
}
.bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::before {
	left: 0;
	border-right-color: #dee3eb;
}
.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-right .arrow::after {
	left: 1px;
	border-right-color: #fff;
}
.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
	margin-top: .5rem;
}
.bs-popover-auto[x-placement^=bottom] .arrow, .bs-popover-bottom .arrow {
	top: calc((.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^=bottom] .arrow {
	&::after, &::before {
		border-width: 0 .25rem .5rem .25rem;
	}
}
.bs-popover-bottom .arrow {
	&::after, &::before {
		border-width: 0 .25rem .5rem .25rem;
	}
}
.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
	top: 0;
	border-bottom-color: #dee3eb;
}
.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
	top: 1px;
	border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: .5rem;
	margin-left: -.25rem;
	content: "";
	border-bottom: 1px solid #fff;
}
.bs-popover-auto[x-placement^=left], .bs-popover-left {
	margin-right: .5rem;
}
.bs-popover-auto[x-placement^=left] .arrow, .bs-popover-left .arrow {
	right: calc((.5rem + 1px) * -1);
	width: .5rem;
	height: .5rem;
	margin: 3px 0;
}
.bs-popover-auto[x-placement^=left] .arrow {
	&::after, &::before {
		border-width: .25rem 0 .25rem .5rem;
	}
}
.bs-popover-left .arrow {
	&::after, &::before {
		border-width: .25rem 0 .25rem .5rem;
	}
}
.bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::before {
	right: 0;
	border-left-color: #dee3eb;
}
.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-left .arrow::after {
	right: 1px;
	border-left-color: #fff;
}
.popover-header {
	padding: .5rem .75rem;
	margin-bottom: 0;
	font-size: .9375rem;
	color: inherit;
	background-color: #fff;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(3px - 1px);
	border-top-right-radius: calc(3px - 1px);
	&:empty {
		display: none;
	}
}
.popover-body {
	padding: .75rem 1rem;
	color: #5f6877;
}
#popup {
	position: absolute;
	width: auto;
	height: 30px;
	background: #fe6b1f;
	display: none;
	color: #fff;
	border-radius: 5px;
}
#copy {
	background: 0 0;
	color: #fff;
	font-weight: 700;
	padding: 8px 25px;
	border: 0;
}
.bd-example {
	position: relative;
	padding: 1rem;
	margin: 1rem -1rem;
	border-width: .2rem 0 0;
}
.bd-example-tooltip-static .tooltip {
	position: relative;
	display: inline-block;
	margin: 10px 20px;
	opacity: 1;
}
.tooltip {
	&.bs-tether-element-attached-bottom, &.tooltip-top {
		padding: 5px 0;
		margin-top: -3px;
	}
	&.bs-tether-element-attached-left, &.tooltip-right {
		padding: 0 5px;
		margin-left: 3px;
	}
	&.bs-tether-element-attached-bottom .tooltip-inner::before, &.tooltip-top .tooltip-inner::before {
		bottom: 0;
		left: 50%;
		margin-left: -5px;
		content: "";
		border-width: 5px 5px 0;
		border-top-color: #000;
	}
	&.bs-tether-element-attached-left .tooltip-inner::before, &.tooltip-right .tooltip-inner::before {
		top: 50%;
		left: 0;
		margin-top: -5px;
		content: "";
		border-width: 5px 5px 5px 0;
		border-right-color: #000;
	}
	&.bs-tether-element-attached-top, &.tooltip-bottom {
		padding: 5px 0;
		margin-top: 3px;
	}
	&.bs-tether-element-attached-top .tooltip-inner::before, &.tooltip-bottom .tooltip-inner::before {
		top: 0;
		left: 50%;
		margin-left: -5px;
		content: "";
		border-width: 0 5px 5px;
		border-bottom-color: #000;
	}
	&.bs-tether-element-attached-right, &.tooltip-left {
		padding: 0 5px;
		margin-left: -3px;
	}
	&.bs-tether-element-attached-right .tooltip-inner::before, &.tooltip-left .tooltip-inner::before {
		top: 50%;
		right: 0;
		margin-top: -5px;
		content: "";
		border-width: 5px 0 5px 5px;
		border-left-color: #000;
	}
}
.tooltip-inner::before {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
.pop-secondary {
	color: #4d9dff;
}
.popprimary {
	color: #628dea;
}
.popinfo {
	color: #88c8f7;
}
.popsuccess {
	color: #bfff80;
}
.popdanger {
	color: #ea6262;
}
.popwarning {
	color: #f7db6e;
}
/*-------- Tags -------*/
