$body-color: #f5f4fb;	
$text-color: #333063;
$border-color:#e5e4f0;
$border-dark-color: #efecfb;
$form-background: #f8f7fc;
$color-dark-purple: #282f3f;
$btn-hover-dark-purple:#282f3f;
$color-success: #3eb750;
$btn-hover-success:#349e44;
$color-info: #23bcb9;
$btn-hover-info:#23bcb9;
$color-warning: #ffb609;
$btn-hover-warning:#ffb609;
$color-danger: #f96768;
$btn-hover-danger:#e44f50;
$color-blue: #467fcf;
$btn-hover-blue:#316cbe;
$color-azure: #00d6e6;
$btn-hover-azure:#316cbe;
$color-indigo: #6574cd;
$btn-hover-indigo:#0ea9b5;
$color-purple: #604dd8;
$btn-hover-purple:#923ce6;
$color-pink: #ec296b;
$btn-hover-pink:#e61470;
$color-red: #fe6567;
$btn-hover-red:#b3160b;
$color-orange: #e67605;
$btn-hover-orange:#fd811e;
$color-yellow: #ffb609;
$btn-hover-yellow:#fa3;
$color-lime: #63e600;
$btn-hover-lime:#69b829;
$color-green: #3eb750;
$btn-hover-green:#3adfab;
$color-teal: #05e6e6;
$btn-hover-teal:#05fafa;
$color-cyan: #17a2b8;
$btn-hover-cyan:#138496;
$color-gray: #868e96;
$btn-hover-gray:#727b84;
$color-gray-dark: #343a40;
$btn-hover-gray-dark:#23272b;
$white:#fff;
	
	/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/* Theme 01*/
$color-primary: #7167ff;
$btn-hover-primary:#ed3025;
$color-secondary: #f96768;
$btn-hover-secondary:#e44f50;

/* Theme 02*/
$color-primary-02:#0b75b3;
$btn-hover-primary-02:#1571a7;
$color-secondary-02: #ec296b;
$btn-hover-secondary-02:#c7275d;

/* Theme 03*/
$color-primary-03:#3f469a;
$btn-hover-primary-03:#123884;
$color-secondary-03: #f0162f;
$btn-hover-secondary-03:#d3142a;

/* Theme 04*/
$color-primary-04:#5719ec;
$btn-hover-primary-04:#341384;
$color-secondary-04: #ea167c;
$btn-hover-secondary-04:#960f50;

/* Theme 05*/
$color-primary-05:#158878;
$btn-hover-primary-05:#237936;
$color-secondary-05: #e91e63;
$btn-hover-secondary-05:#cc2f64;

/* Theme 06*/
$color-primary-06:#711b8a;
$btn-hover-primary-06:#72128e;
$color-secondary-06: #e67c18;
$btn-hover-secondary-06:#d07219;

/* Theme 07*/
$color-primary-07:#8c37ff;
$btn-hover-primary-07:#8337ea;
$color-secondary-07: #f94a36;
$btn-hover-secondary-07:#e03b28;

/* Theme 08*/
$color-primary-08:#007bff;
$btn-hover-primary-08:#0c6fda;
$color-secondary-08: #ea167c;
$btn-hover-secondary-08:#960f50;

/* Theme 09*/
$color-primary-09:#5e4796;
$btn-hover-primary-09:#4f3294;
$color-secondary-09: #e67605;
$btn-hover-secondary-09:#ca6908;

/* Theme 10*/
$color-primary-10:#004063;
$btn-hover-primary-10:#004063;
$color-secondary-10: #ff8e01;
$btn-hover-secondary-10:#de8617;