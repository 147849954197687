/*-------- Alignments & Backgrounds -------*/

.container, .container-fluid {
	width: 100%;
	padding-right: .75rem;
	padding-left: .75rem;
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}
@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -.75rem;
	margin-left: -.75rem;
}
.no-gutters {
	margin-right: 0;
	margin-left: 0;
	> {
		.col, [class*=col-] {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
.align-baseline {
	vertical-align: baseline !important;
}
.align-top {
	vertical-align: top !important;
}
.align-middle {
	vertical-align: middle !important;
}
.align-bottom {
	vertical-align: bottom !important;
}
.align-text-bottom {
	vertical-align: text-bottom !important;
}
.align-text-top {
	vertical-align: text-top !important;
}
.text-success {
	color: #3eb750 !important;
}
a.text-success {
	&:focus, &:hover {
		color: #3adfab !important;
	}
}
.text-info {
	color: #23bcb7 !important;
}
a.text-info {
	&:focus, &:hover {
		color: #1594ef !important;
	}
}
.text-warning {
	color: #ffb609 !important;
}
a.text-warning {
	&:focus, &:hover {
		color: #fa3 !important;
	}
}
.text-danger {
	color: #fe6567 !important;
}
a.text-danger {
	&:focus, &:hover {
		color: #fb5b50 !important;
	}
}
.text-light {
	color: #f8f9fa !important;
}
a.text-light {
	&:focus, &:hover {
		color: #e4e2ef !important;
	}
}
.text-dark, .text-body {
	color: $text-color !important;
}
.text-muted {
	color: #797896 !important;
}
.text-black-50 {
	color: rgba(49, 45, 101, 0.5) !important;
}
.text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}
.text-white-80 {
	color: rgba(255, 255, 255, 0.8) !important;
}
.bg-success-transparent {
	background-color: rgba(62, 183, 80, 0.1) !important;
}
.bg-info-transparent {
	background-color: rgba(35, 188, 183, 0.1) !important;
}
.bg-warning-transparent {
	background-color: rgba(255, 162, 43, 0.1) !important;
}
.bg-danger-transparent {
	background-color: rgba(214, 15, 2, 0.1) !important;
}
.bg-pink-transparent {
	background-color: rgba(255, 43, 136, 0.1) !important;
}
.bg-purple-transparent {
	background-color: rgba(96, 77, 216, 0.1) !important;
}
.bg-dark-transparent {
	background-color: rgba(49, 45, 101, 0.3) !important;
}
.bg-dark-transparent6 {
	background-color: rgba(49, 45, 101, 0.6) !important;
}
.bg-white-transparent {
	background-color: rgba(255, 255, 255, 0.18) !important;
}
.box-shadow {
	box-shadow: 0 8px 10px 0 rgba(49, 45, 101, 0.15) !important;
}
.bg-success {
	background-color: #3eb750 !important;
}
a.bg-success {
	&:focus, &:hover {
		background-color: #3adfab !important;
	}
}
button.bg-success {
	&:focus, &:hover {
		background-color: #3adfab !important;
	}
}
.bg-info {
	background-color: #23bcb7 !important;
}
a.bg-info {
	&:focus, &:hover {
		background-color: #1594ef !important;
	}
}
button.bg-info {
	&:focus, &:hover {
		background-color: #1594ef !important;
	}
}
.bg-warning {
	background-color: #ffb609 !important;
	color: #fff;
}
a.bg-warning {
	&:focus, &:hover {
		background-color: #fa3 !important;
	}
}
button.bg-warning {
	&:focus, &:hover {
		background-color: #fa3 !important;
	}
}
.bg-danger {
	background-color: #fe6567 !important;
}
a.bg-danger {
	&:focus, &:hover {
		background-color: #fb5b50 !important;
	}
}
button.bg-danger {
	&:focus, &:hover {
		background-color: #fb5b50 !important;
	}
}
.bg-light {
	background-color: $border-color !important;
}
a.bg-light {
	&:focus, &:hover {
		background-color: #e4e2ef !important;
	}
}
button.bg-light {
	&:focus, &:hover {
		background-color: #e4e2ef !important;
	}
}
.bg-light-50 {
	background-color: $body-color !important;
}
.bg-light-30 {
	background-color: $form-background !important;
}
.bg-dark {
	background-color: #19181f !important;
}
a.bg-dark {
	&:focus, &:hover {
		background-color: #313031 !important;
	}
}
button.bg-dark {
	&:focus, &:hover {
		background-color: #313031 !important;
	}
}
.bg-white {
	background-color: #fff !important;
}
.bg-dark-purple {
	background-color: #160346 !important;
}
a.bg-dark-purple {
	&:focus, &:hover {
		background-color: #0f0518 !important;
	}
}
button.bg-dark-purple {
	&:focus, &:hover {
		background-color: #0f0518 !important;
	}
}
.bg-transparent {
	background-color: transparent !important;
}
.bg-blue-lightest {
	background-color: #f6f7fb !important;
}
a.bg-blue-lightest {
	&:focus, &:hover {
		background-color: #c5d5ef !important;
	}
}
button.bg-blue-lightest {
	&:focus, &:hover {
		background-color: #c5d5ef !important;
	}
}
.bg-blue-lighter {
	background-color: #c8d9f1 !important;
}
a.bg-blue-lighter {
	&:focus, &:hover {
		background-color: #9fbde7 !important;
	}
}
button.bg-blue-lighter {
	&:focus, &:hover {
		background-color: #9fbde7 !important;
	}
}
.bg-blue-light {
	background-color: #7ea5dd !important;
}
a.bg-blue-light {
	&:focus, &:hover {
		background-color: #5689d2 !important;
	}
}
button.bg-blue-light {
	&:focus, &:hover {
		background-color: #5689d2 !important;
	}
}
.bg-blue-dark {
	background-color: #3866a6 !important;
}
a.bg-blue-dark {
	&:focus, &:hover {
		background-color: #2b4f80 !important;
	}
}
button.bg-blue-dark {
	&:focus, &:hover {
		background-color: #2b4f80 !important;
	}
}
.bg-blue-darker {
	background-color: #1c3353 !important;
}
a.bg-blue-darker {
	&:focus, &:hover {
		background-color: #0f1c2d !important;
	}
}
button.bg-blue-darker {
	&:focus, &:hover {
		background-color: #0f1c2d !important;
	}
}
.bg-blue-darkest {
	background-color: #0e1929 !important;
}
a.bg-blue-darkest {
	&:focus, &:hover {
		background-color: #010203 !important;
	}
}
button.bg-blue-darkest {
	&:focus, &:hover {
		background-color: #010203 !important;
	}
}
.bg-purssianblue {
	background-color: #362f71;
}
a.bg-purssianblue-lightest {
	&:focus, &:hover {
		background-color: #3f3688 !important;
	}
}
button.bg-purssianblue-lightest {
	&:focus, &:hover {
		background-color: #3f3688 !important;
	}
}
.bg-indigo-lightest {
	background-color: #f0f1fa !important;
}
a.bg-indigo-lightest {
	&:focus, &:hover {
		background-color: #cacded !important;
	}
}
button.bg-indigo-lightest {
	&:focus, &:hover {
		background-color: #cacded !important;
	}
}
.bg-indigo-lighter {
	background-color: #d1d5f0 !important;
}
a.bg-indigo-lighter {
	&:focus, &:hover {
		background-color: #abb2e3 !important;
	}
}
button.bg-indigo-lighter {
	&:focus, &:hover {
		background-color: #abb2e3 !important;
	}
}
.bg-indigo-light {
	background-color: #939edc !important;
}
a.bg-indigo-light {
	&:focus, &:hover {
		background-color: #6c7bd0 !important;
	}
}
button.bg-indigo-light {
	&:focus, &:hover {
		background-color: #6c7bd0 !important;
	}
}
.bg-indigo-dark {
	background-color: #515da4 !important;
}
a.bg-indigo-dark {
	&:focus, &:hover {
		background-color: #404a82 !important;
	}
}
button.bg-indigo-dark {
	&:focus, &:hover {
		background-color: #404a82 !important;
	}
}
.bg-indigo-darker {
	background-color: #282e52 !important;
}
a.bg-indigo-darker {
	&:focus, &:hover {
		background-color: #171b30 !important;
	}
}
button.bg-indigo-darker {
	&:focus, &:hover {
		background-color: #171b30 !important;
	}
}
.bg-indigo-darkest {
	background-color: #141729 !important;
}
a.bg-indigo-darkest {
	&:focus, &:hover {
		background-color: #030407 !important;
	}
}
button.bg-indigo-darkest {
	&:focus, &:hover {
		background-color: #030407 !important;
	}
}
.bg-purple-lightest {
	background-color: #f6effd !important;
}
a.bg-purple-lightest {
	&:focus, &:hover {
		background-color: #ddc2f7 !important;
	}
}
button.bg-purple-lightest {
	&:focus, &:hover {
		background-color: #ddc2f7 !important;
	}
}
.bg-purple-lighter {
	background-color: #e4cff9 !important;
}
a.bg-purple-lighter {
	&:focus, &:hover {
		background-color: #cba2f3 !important;
	}
}
button.bg-purple-lighter {
	&:focus, &:hover {
		background-color: #cba2f3 !important;
	}
}
.bg-purple-light {
	background-color: #c08ef0 !important;
}
a.bg-purple-light {
	&:focus, &:hover {
		background-color: #a761ea !important;
	}
}
button.bg-purple-light {
	&:focus, &:hover {
		background-color: #a761ea !important;
	}
}
.bg-purple-dark {
	background-color: #844bbb !important;
}
a.bg-purple-dark {
	&:focus, &:hover {
		background-color: #6a3a99 !important;
	}
}
button.bg-purple-dark {
	&:focus, &:hover {
		background-color: #6a3a99 !important;
	}
}
.bg-purple-darker {
	background-color: #42265e !important;
}
a.bg-purple-darker {
	&:focus, &:hover {
		background-color: #29173a !important;
	}
}
button.bg-purple-darker {
	&:focus, &:hover {
		background-color: #29173a !important;
	}
}
.bg-purple-darkest {
	background-color: #21132f !important;
}
a.bg-purple-darkest {
	&:focus, &:hover {
		background-color: #08040b !important;
	}
}
button.bg-purple-darkest {
	&:focus, &:hover {
		background-color: #08040b !important;
	}
}
.bg-pink-lightest {
	background-color: #fef0f5 !important;
}
a.bg-pink-lightest {
	&:focus, &:hover {
		background-color: #fbc0d5 !important;
	}
}
button.bg-pink-lightest {
	&:focus, &:hover {
		background-color: #fbc0d5 !important;
	}
}
.bg-pink-lighter {
	background-color: #fcd3e1 !important;
}
a.bg-pink-lighter {
	&:focus, &:hover {
		background-color: #f9a3c0 !important;
	}
}
button.bg-pink-lighter {
	&:focus, &:hover {
		background-color: #f9a3c0 !important;
	}
}
.bg-pink-light {
	background-color: #f999b9 !important;
}
a.bg-pink-light {
	&:focus, &:hover {
		background-color: #f66998 !important;
	}
}
button.bg-pink-light {
	&:focus, &:hover {
		background-color: #f66998 !important;
	}
}
.bg-pink-dark {
	background-color: #c5577c !important;
}
a.bg-pink-dark {
	&:focus, &:hover {
		background-color: #ad3c62 !important;
	}
}
button.bg-pink-dark {
	&:focus, &:hover {
		background-color: #ad3c62 !important;
	}
}
.bg-pink-darker {
	background-color: #622c3e !important;
}
a.bg-pink-darker {
	&:focus, &:hover {
		background-color: #3f1c28 !important;
	}
}
button.bg-pink-darker {
	&:focus, &:hover {
		background-color: #3f1c28 !important;
	}
}
.bg-pink-darkest {
	background-color: #31161f !important;
}
a.bg-pink-darkest {
	&:focus, &:hover {
		background-color: #0e0609 !important;
	}
}
button.bg-pink-darkest {
	&:focus, &:hover {
		background-color: #0e0609 !important;
	}
}
.bg-red-lightest {
	background-color: #fae9e9 !important;
}
a.bg-red-lightest {
	&:focus, &:hover {
		background-color: #f1bfbf !important;
	}
}
button.bg-red-lightest {
	&:focus, &:hover {
		background-color: #f1bfbf !important;
	}
}
.bg-red-lighter {
	background-color: #f0bcbc !important;
}
a.bg-red-lighter {
	&:focus, &:hover {
		background-color: #e79292 !important;
	}
}
button.bg-red-lighter {
	&:focus, &:hover {
		background-color: #e79292 !important;
	}
}
.bg-red-light {
	background-color: #dc6362 !important;
}
a.bg-red-light {
	&:focus, &:hover {
		background-color: #d33a38 !important;
	}
}
button.bg-red-light {
	&:focus, &:hover {
		background-color: #d33a38 !important;
	}
}
.bg-red-dark {
	background-color: #a41a19 !important;
}
a.bg-red-dark {
	&:focus, &:hover {
		background-color: #781312 !important;
	}
}
button.bg-red-dark {
	&:focus, &:hover {
		background-color: #781312 !important;
	}
}
.bg-red-darker {
	background-color: #520d0c !important;
}
a.bg-red-darker {
	&:focus, &:hover {
		background-color: #260605 !important;
	}
}
button.bg-red-darker {
	&:focus, &:hover {
		background-color: #260605 !important;
	}
}
.bg-red-darkest {
	background-color: #290606 !important;
}
a.bg-red-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
button.bg-red-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
.bg-orange-lightest {
	background-color: #fff5ec !important;
}
a.bg-orange-lightest {
	&:focus, &:hover {
		background-color: #ffdab9 !important;
	}
}
button.bg-orange-lightest {
	&:focus, &:hover {
		background-color: #ffdab9 !important;
	}
}
.bg-orange-lighter {
	background-color: #fee0c7 !important;
}
a.bg-orange-lighter {
	&:focus, &:hover {
		background-color: #fdc495 !important;
	}
}
button.bg-orange-lighter {
	&:focus, &:hover {
		background-color: #fdc495 !important;
	}
}
.bg-orange-light {
	background-color: #feb67c !important;
}
a.bg-orange-light {
	&:focus, &:hover {
		background-color: #fe9a49 !important;
	}
}
button.bg-orange-light {
	&:focus, &:hover {
		background-color: #fe9a49 !important;
	}
}
.bg-orange-dark {
	background-color: #ca7836 !important;
}
a.bg-orange-dark {
	&:focus, &:hover {
		background-color: #a2602b !important;
	}
}
button.bg-orange-dark {
	&:focus, &:hover {
		background-color: #a2602b !important;
	}
}
.bg-orange-darker {
	background-color: #653c1b !important;
}
a.bg-orange-darker {
	&:focus, &:hover {
		background-color: #3d2410 !important;
	}
}
button.bg-orange-darker {
	&:focus, &:hover {
		background-color: #3d2410 !important;
	}
}
.bg-orange-darkest {
	background-color: #331e0e !important;
}
a.bg-orange-darkest {
	&:focus, &:hover {
		background-color: #0b0603 !important;
	}
}
button.bg-orange-darkest {
	&:focus, &:hover {
		background-color: #0b0603 !important;
	}
}
.bg-yellow-lightest {
	background-color: #fef9e7 !important;
}
a.bg-yellow-lightest {
	&:focus, &:hover {
		background-color: #fcedb6 !important;
	}
}
button.bg-yellow-lightest {
	&:focus, &:hover {
		background-color: #fcedb6 !important;
	}
}
.bg-yellow-lighter {
	background-color: #fbedb7 !important;
}
a.bg-yellow-lighter {
	&:focus, &:hover {
		background-color: #f8e187 !important;
	}
}
button.bg-yellow-lighter {
	&:focus, &:hover {
		background-color: #f8e187 !important;
	}
}
.bg-yellow-light {
	background-color: #f5d657 !important;
}
a.bg-yellow-light {
	&:focus, &:hover {
		background-color: #f2ca27 !important;
	}
}
button.bg-yellow-light {
	&:focus, &:hover {
		background-color: #f2ca27 !important;
	}
}
.bg-yellow-dark {
	background-color: #c19d0c !important;
}
a.bg-yellow-dark {
	&:focus, &:hover {
		background-color: #917609 !important;
	}
}
button.bg-yellow-dark {
	&:focus, &:hover {
		background-color: #917609 !important;
	}
}
.bg-yellow-darker {
	background-color: #604e06 !important;
}
a.bg-yellow-darker {
	&:focus, &:hover {
		background-color: #302703 !important;
	}
}
button.bg-yellow-darker {
	&:focus, &:hover {
		background-color: #302703 !important;
	}
}
.bg-yellow-darkest {
	background-color: #302703 !important;
}
a.bg-yellow-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
button.bg-yellow-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
.bg-green-lightest {
	background-color: #eff8e6 !important;
}
a.bg-green-lightest {
	&:focus, &:hover {
		background-color: #d6edbe !important;
	}
}
button.bg-green-lightest {
	&:focus, &:hover {
		background-color: #d6edbe !important;
	}
}
.bg-green-lighter {
	background-color: #cfeab3 !important;
}
a.bg-green-lighter {
	&:focus, &:hover {
		background-color: #b6df8b !important;
	}
}
button.bg-green-lighter {
	&:focus, &:hover {
		background-color: #b6df8b !important;
	}
}
.bg-green-light {
	background-color: #8ecf4d !important;
}
a.bg-green-light {
	&:focus, &:hover {
		background-color: #75b831 !important;
	}
}
button.bg-green-light {
	&:focus, &:hover {
		background-color: #75b831 !important;
	}
}
.bg-green-dark {
	background-color: #4b9500 !important;
}
a.bg-green-dark {
	&:focus, &:hover {
		background-color: #316200 !important;
	}
}
button.bg-green-dark {
	&:focus, &:hover {
		background-color: #316200 !important;
	}
}
.bg-green-darker {
	background-color: #264a00 !important;
}
a.bg-green-darker {
	&:focus, &:hover {
		background-color: #0c1700 !important;
	}
}
button.bg-green-darker {
	&:focus, &:hover {
		background-color: #0c1700 !important;
	}
}
.bg-green-darkest {
	background-color: #132500 !important;
}
a.bg-green-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
button.bg-green-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
.bg-teal-lightest {
	background-color: #eafaf8 !important;
}
a.bg-teal-lightest {
	&:focus, &:hover {
		background-color: #c1f0ea !important;
	}
}
button.bg-teal-lightest {
	&:focus, &:hover {
		background-color: #c1f0ea !important;
	}
}
.bg-teal-lighter {
	background-color: #bfefea !important;
}
a.bg-teal-lighter {
	&:focus, &:hover {
		background-color: #96e5dd !important;
	}
}
button.bg-teal-lighter {
	&:focus, &:hover {
		background-color: #96e5dd !important;
	}
}
.bg-teal-light {
	background-color: #6bdbcf !important;
}
a.bg-teal-light {
	&:focus, &:hover {
		background-color: #42d1c2 !important;
	}
}
button.bg-teal-light {
	&:focus, &:hover {
		background-color: #42d1c2 !important;
	}
}
.bg-teal-dark {
	background-color: #22a295 !important;
}
a.bg-teal-dark {
	&:focus, &:hover {
		background-color: #19786e !important;
	}
}
button.bg-teal-dark {
	&:focus, &:hover {
		background-color: #19786e !important;
	}
}
.bg-teal-darker {
	background-color: #11514a !important;
}
a.bg-teal-darker {
	&:focus, &:hover {
		background-color: #082723 !important;
	}
}
button.bg-teal-darker {
	&:focus, &:hover {
		background-color: #082723 !important;
	}
}
.bg-teal-darkest {
	background-color: #092925 !important;
}
a.bg-teal-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
button.bg-teal-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
.bg-cyan-lightest {
	background-color: #e8f6f8 !important;
}
a.bg-cyan-lightest {
	&:focus, &:hover {
		background-color: #c1e7ec !important;
	}
}
button.bg-cyan-lightest {
	&:focus, &:hover {
		background-color: #c1e7ec !important;
	}
}
.bg-cyan-lighter {
	background-color: #b9e3ea !important;
}
a.bg-cyan-lighter {
	&:focus, &:hover {
		background-color: #92d3de !important;
	}
}
button.bg-cyan-lighter {
	&:focus, &:hover {
		background-color: #92d3de !important;
	}
}
.bg-cyan-light {
	background-color: #5dbecd !important;
}
a.bg-cyan-light {
	&:focus, &:hover {
		background-color: #3aabbd !important;
	}
}
button.bg-cyan-light {
	&:focus, &:hover {
		background-color: #3aabbd !important;
	}
}
.bg-cyan-dark {
	background-color: #128293 !important;
}
a.bg-cyan-dark {
	&:focus, &:hover {
		background-color: #0c5a66 !important;
	}
}
button.bg-cyan-dark {
	&:focus, &:hover {
		background-color: #0c5a66 !important;
	}
}
.bg-cyan-darker {
	background-color: #09414a !important;
}
a.bg-cyan-darker {
	&:focus, &:hover {
		background-color: #03191d !important;
	}
}
button.bg-cyan-darker {
	&:focus, &:hover {
		background-color: #03191d !important;
	}
}
.bg-cyan-darkest {
	background-color: #052025 !important;
}
a.bg-cyan-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
button.bg-cyan-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
.bg-white-lightest {
	background-color: #fff !important;
}
a.bg-white-lightest {
	&:focus, &:hover {
		background-color: #e6e5e5 !important;
	}
}
button.bg-white-lightest {
	&:focus, &:hover {
		background-color: #e6e5e5 !important;
	}
}
.bg-white-lighter {
	background-color: #fff !important;
}
a.bg-white-lighter {
	&:focus, &:hover {
		background-color: #e6e5e5 !important;
	}
}
button.bg-white-lighter {
	&:focus, &:hover {
		background-color: #e6e5e5 !important;
	}
}
.bg-white-light {
	background-color: #fff !important;
}
a.bg-white-light {
	&:focus, &:hover {
		background-color: #e6e5e5 !important;
	}
}
button.bg-white-light {
	&:focus, &:hover {
		background-color: #e6e5e5 !important;
	}
}
.bg-white-dark {
	background-color: #ccc !important;
}
a.bg-white-dark {
	&:focus, &:hover {
		background-color: #b3b2b2 !important;
	}
}
button.bg-white-dark {
	&:focus, &:hover {
		background-color: #b3b2b2 !important;
	}
}
.bg-white-darker {
	background-color: #666 !important;
}
a.bg-white-darker {
	&:focus, &:hover {
		background-color: #4d4c4c !important;
	}
}
button.bg-white-darker {
	&:focus, &:hover {
		background-color: #4d4c4c !important;
	}
}
.bg-white-darkest {
	background-color: $text-color !important;
}
a.bg-white-darkest {
	&:focus, &:hover {
		background-color: #1a1919 !important;
	}
}
button.bg-white-darkest {
	&:focus, &:hover {
		background-color: #1a1919 !important;
	}
}
.bg-gray-lightest {
	background-color: #f3f4f5 !important;
}
a.bg-gray-lightest {
	&:focus, &:hover {
		background-color: #d7dbde !important;
	}
}
button.bg-gray-lightest {
	&:focus, &:hover {
		background-color: #d7dbde !important;
	}
}
.bg-gray-lighter {
	background-color: #dbdde0 !important;
}
a.bg-gray-lighter {
	&:focus, &:hover {
		background-color: #c0c3c8 !important;
	}
}
button.bg-gray-lighter {
	&:focus, &:hover {
		background-color: #c0c3c8 !important;
	}
}
.bg-gray-light {
	background-color: #aab0b6 !important;
}
a.bg-gray-light {
	&:focus, &:hover {
		background-color: #8f979e !important;
	}
}
button.bg-gray-light {
	&:focus, &:hover {
		background-color: #8f979e !important;
	}
}
.bg-gray-dark {
	background-color: #6b7278 !important;
}
a.bg-gray-dark {
	&:focus, &:hover {
		background-color: #53585d !important;
	}
}
button.bg-gray-dark {
	&:focus, &:hover {
		background-color: #53585d !important;
	}
}
.bg-gray-darker {
	background-color: #36393c !important;
}
a.bg-gray-darker {
	&:focus, &:hover {
		background-color: #1e2021 !important;
	}
}
button.bg-gray-darker {
	&:focus, &:hover {
		background-color: #1e2021 !important;
	}
}
.bg-gray-darkest {
	background-color: #1b1c1e !important;
}
a.bg-gray-darkest {
	&:focus, &:hover {
		background-color: #030303 !important;
	}
}
button.bg-gray-darkest {
	&:focus, &:hover {
		background-color: #030303 !important;
	}
}
.bg-gray-dark-lightest {
	background-color: #ebebec !important;
}
a.bg-gray-dark-lightest {
	&:focus, &:hover {
		background-color: #d1d1d3 !important;
	}
}
button.bg-gray-dark-lightest {
	&:focus, &:hover {
		background-color: #d1d1d3 !important;
	}
}
.bg-gray-dark-lighter {
	background-color: #c2c4c6 !important;
}
a.bg-gray-dark-lighter {
	&:focus, &:hover {
		background-color: #a8abad !important;
	}
}
button.bg-gray-dark-lighter {
	&:focus, &:hover {
		background-color: #a8abad !important;
	}
}
.bg-gray-dark-light {
	background-color: #717579 !important;
}
a.bg-gray-dark-light {
	&:focus, &:hover {
		background-color: #585c5f !important;
	}
}
button.bg-gray-dark-light {
	&:focus, &:hover {
		background-color: #585c5f !important;
	}
}
.bg-gray-dark-dark {
	background-color: #2a2e33 !important;
}
a.bg-gray-dark-dark {
	&:focus, &:hover {
		background-color: #131517 !important;
	}
}
button.bg-gray-dark-dark {
	&:focus, &:hover {
		background-color: #131517 !important;
	}
}
.bg-gray-dark-darker {
	background-color: #15171a !important;
}
a.bg-gray-dark-darker {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
button.bg-gray-dark-darker {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
.bg-gray-dark-darkest {
	background-color: #0a0c0d !important;
}
a.bg-gray-dark-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
button.bg-gray-dark-darkest {
	&:focus, &:hover {
		background-color: #000 !important;
	}
}
.bg-azure-lightest {
	background-color: #ecf7fe !important;
}
a.bg-azure-lightest {
	&:focus, &:hover {
		background-color: #bce3fb !important;
	}
}
button.bg-azure-lightest {
	&:focus, &:hover {
		background-color: #bce3fb !important;
	}
}
.bg-azure-lighter {
	background-color: #c7e6fb !important;
}
a.bg-azure-lighter {
	&:focus, &:hover {
		background-color: #97d1f8 !important;
	}
}
button.bg-azure-lighter {
	&:focus, &:hover {
		background-color: #97d1f8 !important;
	}
}
.bg-azure-light {
	background-color: #7dc4f6 !important;
}
a.bg-azure-light {
	&:focus, &:hover {
		background-color: #4daef3 !important;
	}
}
button.bg-azure-light {
	&:focus, &:hover {
		background-color: #4daef3 !important;
	}
}
.bg-azure-dark {
	background-color: #3788c2 !important;
}
a.bg-azure-dark {
	&:focus, &:hover {
		background-color: #2c6c9a !important;
	}
}
button.bg-azure-dark {
	&:focus, &:hover {
		background-color: #2c6c9a !important;
	}
}
.bg-azure-darker {
	background-color: #1c4461 !important;
}
a.bg-azure-darker {
	&:focus, &:hover {
		background-color: #112839 !important;
	}
}
button.bg-azure-darker {
	&:focus, &:hover {
		background-color: #112839 !important;
	}
}
.bg-azure-darkest {
	background-color: #0e2230 !important;
}
a.bg-azure-darkest {
	&:focus, &:hover {
		background-color: #020609 !important;
	}
}
button.bg-azure-darkest {
	&:focus, &:hover {
		background-color: #020609 !important;
	}
}
.bg-lime-lightest {
	background-color: #f2fbeb !important;
}
a.bg-lime-lightest {
	&:focus, &:hover {
		background-color: #d6f3c1 !important;
	}
}
button.bg-lime-lightest {
	&:focus, &:hover {
		background-color: #d6f3c1 !important;
	}
}
.bg-lime-lighter {
	background-color: #d7f2c2 !important;
}
a.bg-lime-lighter {
	&:focus, &:hover {
		background-color: #bbe998 !important;
	}
}
button.bg-lime-lighter {
	&:focus, &:hover {
		background-color: #bbe998 !important;
	}
}
.bg-lime-light {
	background-color: #a3e072 !important;
}
a.bg-lime-light {
	&:focus, &:hover {
		background-color: #88d748 !important;
	}
}
button.bg-lime-light {
	&:focus, &:hover {
		background-color: #88d748 !important;
	}
}
.bg-lime-dark {
	background-color: #62a82a !important;
}
a.bg-lime-dark {
	&:focus, &:hover {
		background-color: #4a7f20 !important;
	}
}
button.bg-lime-dark {
	&:focus, &:hover {
		background-color: #4a7f20 !important;
	}
}
.bg-lime-darker {
	background-color: #315415 !important;
}
a.bg-lime-darker {
	&:focus, &:hover {
		background-color: #192b0b !important;
	}
}
button.bg-lime-darker {
	&:focus, &:hover {
		background-color: #192b0b !important;
	}
}
.bg-lime-darkest {
	background-color: #192a0b !important;
}
a.bg-lime-darkest {
	&:focus, &:hover {
		background-color: #010200 !important;
	}
}
button.bg-lime-darkest {
	&:focus, &:hover {
		background-color: #010200 !important;
	}
}
.display-1 i, .display-2 i, .display-3 i, .display-4 i {
	vertical-align: baseline;
	font-size: .815em;
}
.text-inherit {
	color: inherit !important;
}
.text-default {
	color: #3f3e50 !important;
}
.text-muted-dark {
	color: #5f6877 !important;
}
.tracking-tight {
	letter-spacing: -.05em !important;
}
.tracking-normal {
	letter-spacing: 0 !important;
}
.tracking-wide {
	letter-spacing: .05em !important;
}
.leading-none {
	line-height: 1 !important;
}
.leading-tight {
	line-height: 1.3 !important;
}
.leading-normal {
	line-height: 1.5 !important;
}
.leading-normal-2 {
	line-height: 1.8 !important;
}
.leading-loose {
	line-height: 2 !important;
}
.bg-blue {
	background-color: #4049ec !important;
	color: #fff !important;
}
a.bg-blue {
	&:focus, &:hover {
		background-color: #24987e !important;
	}
}
button.bg-blue {
	&:focus, &:hover {
		background-color: #24987e !important;
	}
}
.text-blue {
	color: #3ba2ff !important;
}
.bg-indigo {
	background-color: #6574cd !important;
	color: #fff !important;
}
a.bg-indigo {
	&:focus, &:hover {
		background-color: #3f51c1 !important;
	}
}
button.bg-indigo {
	&:focus, &:hover {
		background-color: #3f51c1 !important;
	}
}
.text-indigo {
	color: #6574cd !important;
}
.bg-purple {
	background-color: #6d33ff !important;
	color: #fff !important;
}
a.bg-purple {
	&:focus, &:hover {
		background-color: #8c31e4 !important;
	}
}
button.bg-purple {
	&:focus, &:hover {
		background-color: #8c31e4 !important;
	}
}
.text-purple {
	color: #6d33ff !important;
}
.text-lightpink-red {
	color: #ff7088 !important;
}
.text-lightgreen {
	color: #26eda2 !important;
}
.bg-pink {
	background-color: #ff2b88 !important;
	color: #fff !important;
}
.bg-darkpink {
	background-color: #fc0f79 !important;
	color: #fff !important;
}
a.bg-pink {
	&:focus, &:hover {
		background-color: #ed3284 !important;
	}
}
button.bg-pink {
	&:focus, &:hover {
		background-color: #ed3284 !important;
	}
}
.text-pink {
	color: #ff2b88 !important;
}
.bg-red {
	background-color: #fe6567 !important;
	color: #fff !important;
}
a.bg-red {
	&:focus, &:hover {
		background-color: #fb5b50 !important;
	}
}
button.bg-red {
	&:focus, &:hover {
		background-color: #fb5b50 !important;
	}
}
.text-red {
	color: #fe6567 !important;
}
.bg-orange {
	background-color: #e67605 !important;
	color: #fff !important;
}
a.bg-orange {
	&:focus, &:hover {
		background-color: #fc7a12 !important;
	}
}
button.bg-orange {
	&:focus, &:hover {
		background-color: #fc7a12 !important;
	}
}
.text-orange {
	color: #e67605 !important;
}
.bg-yellow {
	background-color: #ffb609 !important;
	color: #fff !important;
}
a.bg-yellow {
	&:focus, &:hover {
		background-color: #fa3 !important;
	}
}
button.bg-yellow {
	&:focus, &:hover {
		background-color: #fa3 !important;
	}
}
.text-yellow {
	color: #ffb609 !important;
}
.bg-green {
	background-color: #3eb750 !important;
	color: #fff !important;
}
a.bg-green {
	&:focus, &:hover {
		background-color: #3adfab !important;
	}
}
button.bg-green {
	&:focus, &:hover {
		background-color: #3adfab !important;
	}
}
.text-green {
	color: #3eb750 !important;
}
.bg-teal {
	background-color: #05e6e6 !important;
}
a.bg-teal {
	&:focus, &:hover {
		background-color: #05fafa !important;
	}
}
button.bg-teal {
	&:focus, &:hover {
		background-color: #05fafa !important;
	}
}
.text-teal {
	color: #05e6e6 !important;
}
.bg-cyan {
	background-color: #17a2b8 !important;
	color: #fff !important;
}
a.bg-cyan {
	&:focus, &:hover {
		background-color: #117a8b !important;
	}
}
button.bg-cyan {
	&:focus, &:hover {
		background-color: #117a8b !important;
	}
}
.text-cyan {
	color: #17a2b8 !important;
}
a.bg-white {
	&:focus, &:hover {
		background-color: #e6e5e5 !important;
	}
}
button.bg-white {
	&:focus, &:hover {
		background-color: #e6e5e5 !important;
	}
}
.text-white {
	color: #fff !important;
}
.bg-gray {
	background-color: #868e96 !important;
}
a.bg-gray {
	&:focus, &:hover {
		background-color: #3f3e50 !important;
	}
}
button.bg-gray {
	&:focus, &:hover {
		background-color: #3f3e50 !important;
	}
}
.text-gray {
	color: #858d97 !important;
}
.bg-lightpink-red {
	color: #ff7088 !important;
}
.text-gray-dark {
	color: #343a40 !important;
}
.bg-azure {
	background-color: #23bcb7 !important;
}
a.bg-azure {
	&:focus, &:hover {
		background-color: #1594ef !important;
	}
}
button.bg-azure {
	&:focus, &:hover {
		background-color: #1594ef !important;
	}
}
.text-azure {
	color: #23bcb7 !important;
}
.bg-lime {
	background-color: #63e600 !important;
}
a.bg-lime {
	&:focus, &:hover {
		background-color: #63ad27 !important;
	}
}
button.bg-lime {
	&:focus, &:hover {
		background-color: #63ad27 !important;
	}
}
.text-lime {
	color: #63e600 !important;
}
.bg-purple1 {
	background: #6e26a6;
}
.icon i {
	vertical-align: -1px;
}
a.icon {
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #1a1a1a !important;
	}
}
.o-auto {
	overflow: auto !important;
}
.o-hidden {
	overflow: hidden !important;
}
.border {
	border: 1px solid $border-color !important;
}
.border-top {
	border-top: 1px solid $border-color !important;
}
.border-right {
	border-right: 1px solid $border-color !important;
}
.border-bottom {
	border-bottom: 1px solid $border-color !important;
}
.border-left {
	border-left: 1px solid $border-color !important;
}
.border-0 {
	border: 0 !important;
}
.border-top-0 {
	border-top: 0 !important;
}
.border-right-0 {
	border-right: 0 !important;
}
.border-bottom-0 {
	border-bottom: 0 !important;
}
.border-left-0 {
	border-left: 0 !important;
}
.border-success {
	border-color: #3eb750 !important;
}
.border-info {
	border-color: #23bcb7 !important;
}
.border-warning {
	border-color: #ffb609 !important;
}
.border-danger {
	border-color: #fe6567 !important;
}
.border-light {
	border-color: $border-color !important;
}
.border-width-1 {
	border-width: 1px !important;
}
.border-width-2 {
	border-width: 2px !important;
}
.border-width-3 {
	border-width: 3px !important;
}
.border-width-4 {
	border-width: 4px !important;
}
.border-width-5 {
	border-width: 5px !important;
}
.border-white-transparent {
	border-color: rgba(255, 255, 255, 0.15) !important;
}
.border-white-transparent-5 {
	border-color: rgba(255, 255, 255, 0.5) !important;
}
.border-dark {
	border-color: #343a40 !important;
}
.border-dark-transparent {
	border-color: $border-color !important;
}
.border-white {
	border-color: #fff !important;
}
.rounded {
	border-radius: 3px !important;
}
.rounded7 {
	border-radius: 7px !important;
}
.rounded-top {
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}
.rounded-right {
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
}
.rounded-bottom {
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.rounded-left {
	border-top-left-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.rounded-circle {
	border-radius: 50% !important;
}
.rounded-0 {
	border-radius: 0 !important;
}
.clearfix::after {
	display: block;
	clear: both;
	content: "";
}
.d-none {
	display: none !important;
}
.d-inline {
	display: inline !important;
}
.d-inline-block {
	display: inline-block !important;
}
.d-block {
	display: block !important;
}
.d-table {
	display: table !important;
}
.d-table-row {
	display: table-row !important;
}
.d-table-cell {
	display: table-cell !important;
}
.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}
.d-inline-flex {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}
@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
@media (min-width: 768px) {
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-md-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
@media (min-width: 1200px) {
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
@media print {
	.d-print-none {
		display: none !important;
	}
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-print-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
/*-------- Alignments & Backgrounds -------*/

/*-------- Flex Direction -------*/

.flex-row {
	-ms-flex-direction: row !important;
	flex-direction: row !important;
}
.flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}
.flex-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}
.flex-column-reverse {
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important;
}
.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}
.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important;
}
.flex-fill {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
}
.flex-grow-0 {
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important;
}
.flex-grow-1 {
	-ms-flex-positive: 1 !important;
	flex-grow: 1 !important;
}
.flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important;
}
.flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important;
}
.justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}
.justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}
.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}
.justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}
.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}
.align-items-start {
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}
.align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}
.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}
.align-items-baseline {
	-ms-flex-align: baseline !important;
	align-items: baseline !important;
}
.align-items-stretch {
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
}
.align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;
}
.align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important;
}
.align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important;
}
.align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important;
}
.align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important;
}
.align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important;
}
.align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important;
}
.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
}
.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}
.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
}
.align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important;
}
.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important;
}
@media (min-width: 576px) {
	.flex-sm-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-sm-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-sm-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-sm-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-sm-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-sm-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-sm-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-sm-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-sm-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-sm-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-sm-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-sm-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-sm-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-sm-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}
@media (min-width: 768px) {
	.flex-md-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-md-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-md-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-md-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-md-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-md-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-md-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-md-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-md-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-md-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-md-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-md-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-md-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-md-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-md-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-md-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-md-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-md-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-md-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-md-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-md-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}
@media (min-width: 992px) {
	.flex-lg-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-lg-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-lg-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-lg-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-lg-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-lg-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-lg-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-lg-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-lg-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-lg-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-lg-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-lg-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-lg-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-lg-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}
@media (min-width: 1200px) {
	.flex-xl-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-xl-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-xl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-xl-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-xl-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-xl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-xl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-xl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-xl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-xl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-xl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-xl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-xl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-xl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}
/*-------- Flex Direction -------*/

/*-------- Custom Styles -------*/

.float-left {
	float: left !important;
}
.float-right {
	float: right !important;
}
.float-none {
	float: none !important;
}
@media (min-width: 576px) {
	.float-sm-left {
		float: left !important;
	}
	.float-sm-right {
		float: right !important;
	}
	.float-sm-none {
		float: none !important;
	}
}
@media (min-width: 768px) {
	.float-md-left {
		float: left !important;
	}
	.float-md-right {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
}
@media (min-width: 992px) {
	.float-lg-left {
		float: left !important;
	}
	.float-lg-right {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
}
@media (min-width: 1200px) {
	.float-xl-left {
		float: left !important;
	}
	.float-xl-right {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
}
.position-static {
	position: static !important;
}
.position-relative {
	position: relative !important;
}
.position-absolute {
	position: absolute !important;
}
.position-fixed {
	position: fixed !important;
}
.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}
.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}
@supports (position: -webkit-sticky) or (position: sticky) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}
.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(49, 45, 101, 0.075) !important;
}
.shadow {
	box-shadow: 0 0.5rem 1rem rgba(49, 45, 101, 0.15) !important;
}
.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(49, 45, 101, 0.175) !important;
}
.shadow-none {
	box-shadow: none !important;
}
.text-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.text-justify {
	text-align: justify !important;
}
.text-nowrap {
	white-space: nowrap !important;
}
.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.text-left {
	text-align: left !important;
}
.text-right {
	text-align: right !important;
}
.text-center {
	text-align: center !important;
}
@media (min-width: 576px) {
	.text-sm-left {
		text-align: left !important;
	}
	.text-sm-right {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}
@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}
@media (min-width: 992px) {
	.text-lg-left {
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}
@media (min-width: 1200px) {
	.text-xl-left {
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}
.text-lowercase {
	text-transform: lowercase !important;
}
.text-uppercase {
	text-transform: uppercase !important;
}
.text-capitalize {
	text-transform: capitalize !important;
}
.font-weight-light {
	font-weight: 300 !important;
}
.font-weight-normal {
	font-weight: 400 !important;
}
.font-weight-semibold {
	font-weight: 500 !important;
}
.font-weight-semibold2 {
	font-weight: 600 !important;
}
.font-weight-bold {
	font-weight: 700 !important;
}
.font-italic {
	font-style: italic !important;
}
/*-------- Alignments & Backgrounds -------*/