/*-------- Card Styles -------*/

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	border-radius: 8px;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid $border-color;
	box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
	margin-bottom: 1.5rem;
	width: 100%;
	> {
		hr {
			margin-right: 0;
			margin-left: 0;
		}
		.list-group {
			&:first-child .list-group-item:first-child {
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
			}
			&:last-child .list-group-item:last-child {
				border-bottom-right-radius: 7px;
				border-bottom-left-radius: 7px;
			}
		}
	}
}
.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	margin: 0;
	padding: 1.5rem 1.5rem;
	position: relative;
}
.card-subtitle {
	margin-top: -.75rem;
	margin-bottom: 0;
}
.card-text:last-child {
	margin-bottom: 0;
}
.card-link {
	&:hover {
		text-decoration: none;
	}
	+.card-link {
		margin-left: 1.5rem;
	}
}
.card-header+.list-group .list-group-item:first-child {
	border-top: 0;
}
.card-footer {
	padding: 1.5rem 1.5rem;
	background-color: rgba(49, 45, 101, 0.03);
	border-top: 1px solid $border-color;
	&:last-child {
		border-radius: 0 0 calc(8px - 1px) calc(8px - 1px);
	}
}
.card-header-tabs {
	margin-right: -.75rem;
	margin-bottom: -1.5rem;
	margin-left: -.75rem;
	border-bottom: 0;
	margin: -1.25rem 0;
	line-height: 2rem;
}
.card-header-pills {
	margin-right: -.75rem;
	margin-left: -.75rem;
	margin: -.75rem 0;
}
.card-img {
	width: 100%;
	border-radius: calc(3px - 1px);
	height: 100%;
}
.card-img-top {
	width: 100%;
	border-top-left-radius: calc(3px - 1px);
	border-top-right-radius: calc(3px - 1px);
}
.card-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(3px - 1px);
	border-bottom-left-radius: calc(3px - 1px);
}
.card-deck {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	.card {
		margin-bottom: .75rem;
	}
}
.card-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	>.card {
		margin-bottom: .75rem;
	}
}
.card-columns .card {
	margin-bottom: 1.5rem;
}
.card-blog-overlay .card-header {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.card-blog-overlay1 .card-footer, .card-blog-overlay2 .card-footer {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.card-blog-overlay {
	background: url(../images/thumbnails/thumb2.jpg);
	background-size: cover;
	position: relative;
}
.card-blog-overlay1:before, .card-blog-overlay2:before, .card-blog-overlay:before {
	content: '';
	display: block;
	position: absolute;
	background: rgba(49, 45, 101, 0.2);
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
}
.card-blog-overlay1 {
	background: url(../images/thumbnails/thumb3.jpg);
	background-size: cover;
	background-position: center;
}
.card-blog-overlay2 {
	background: url(../images/banners/banner12.jpg);
	background-size: cover;
	background-position: center;
}
.card-blog-overlay3 {
	background: url(../images/photos/4.jpg);
	background-size: cover;
	background-position: center;
}
.card .card {
	box-shadow: 0 0 40px 0 rgba(234, 238, 249, 0.5);
}
@media print {
	.card {
		box-shadow: none;
		border: none;
	}
}
.card-body+.card-body {
	border-top: 1px solid $border-color;
}
.tilebox-one i {
	font-size: 62px;
}
.card-body> :last-child {
	margin-bottom: 0;
}
@media print {
	.card-body {
		padding: 0;
	}
}
.card-body-scrollable {
	overflow: auto;
}
.card-bottom, .card-footer {
	padding: 1rem 1.5rem;
	background: 0 0;
}
.card-header {
	background: 0 0;
	display: -ms-flexbox;
	display: flex;
	min-height: 3.5rem;
	-ms-flex-align: center;
	align-items: center;
	padding: 1.5rem 1.5rem;
	margin-bottom: 0;
	background-color: transparent;
	border-bottom: 1px solid $border-color;
	.card-title {
		margin-bottom: 0;
	}
	&.border-0+.card-body {
		padding-top: 0;
	}
}
@media print {
	.card-header {
		display: none;
	}
}
.card-img-overlay {
	background-color: rgba(49, 45, 101, 0.4);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}
.card-title {
	font-size: 1.125rem;
	line-height: 1.2;
	font-weight: 600;
}
.reg {
	text-align: center;
	font-size: 50px;
	color: #2e1170;
	float: right;
}
.card-title {
	a {
		color: inherit;
	}
	&:only-child {
		margin-bottom: 0;
	}
}
.card-subtitle, .card-title small {
	color: #cecde0;
	font-size: .875rem;
	display: block;
	margin: -.75rem 0 1rem;
	line-height: 1.1;
	font-weight: 400;
}
.card-table {
	margin-bottom: 0;
	tr {
		&:first-child {
			td, th {
				border-top: 0;
			}
		}
		td:first-child, th:first-child {
			padding-left: 1.5rem;
		}
		td:last-child, th:last-child {
			padding-right: 1.5rem;
		}
	}
}
.card-body+.card-table {
	border-top: 1px solid $border-color;
}
.card-profile .card-header {
	height: 5rem;
	background-size: cover;
	border: 0;
}
.profie-img .flex-md-row img {
	width: 60px;
}
.card-profile-img {
	max-width: 6rem;
	margin-bottom: 1rem;
	border: 3px solid #fff;
	border-radius: 100%;
	box-shadow: 0 1px 1px rgba(49, 45, 101, 0.1);
}
.card-body+.card-list-group {
	border-top: 1px solid $border-color;
}
.card-list-group .list-group-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	&:last-child {
		border-bottom: 0;
	}
	&:first-child {
		border-top: 0;
	}
}
.card-header-tabs .nav-item {
	margin-bottom: 1px;
}
.card-aside {
	-ms-flex-direction: row;
	flex-direction: row;
}
.card-aside-column {
	min-width: 5rem;
	width: 30%;
	-ms-flex: 0 0 30%;
	flex: 0 0 30%;
	background: no-repeat center/cover;
}
.card-value {
	font-size: 1rem;
	line-height: 3.4rem;
	height: 3.4rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 400;
	i {
		vertical-align: middle;
	}
}
.card-chart-bg {
	height: 4rem;
	margin-top: -1rem;
	position: relative;
	z-index: 1;
	overflow: hidden;
}
.list-media {
	.media-img {
		position: relative;
		float: left;
		width: 48px;
		margin-right: 20px;
	}
	.info {
		padding-left: 55px;
		min-height: 40px;
		height: auto;
		position: relative;
		h4 a {
			font-size: 16px;
			margin-top: 10px;
		}
		p {
			font-size: 13px;
		}
		.text-right {
			right: 0;
			color: #8a8a8a;
			top: 50%;
			font-size: 12px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			margin-top: -28px;
		}
	}
}
.card-options {
	margin-left: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-order: 100;
	order: 100;
	margin-right: -.5rem;
	color: #cecde0;
	-ms-flex-item-align: center;
	align-self: center;
	a:not(.btn) {
		margin-left: .5rem;
		color: #b0bcd0;
		font-size: 14px;
		display: inline-block;
		min-width: 1rem;
		&:hover {
			text-decoration: none;
			color: #cecde0;
		}
		i {
			font-size: 1rem;
			vertical-align: middle;
		}
	}
	.dropdown-toggle:after {
		display: none;
	}
}
.widgets-cards .wrp {
	&.icon-circle {
		width: 73px;
		height: 73px;
		border-radius: 50%;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		padding: 0;
		margin-right: 15px;
		i {
			font-size: 1.6rem;
			color: #fff;
		}
	}
	p {
		margin-bottom: 0;
		line-height: 1;
		margin-bottom: 10px;
		&:first-child {
			font-weight: 600;
			font-size: 30px;
		}
	}
}
ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: .5rem;
}
.legend li {
	padding: 5px;
	display: -webkit-inline-box;
}
.stretch-card {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: stretch;
	justify-content: stretch;
	>.card {
		width: 100%;
		min-width: 100%;
	}
}
.icon-size {
	font-size: 2.5rem !important;
}
.card-img-absolute {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
}
.statistics-box {
	position: relative;
	padding-left: 80px;
	padding-top: 14px;
	min-height: 80px;
	.ico {
		&.small {
			font-weight: 900;
			font-size: 42px;
		}
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 80px;
		font-size: 70px;
		line-height: 80px;
		text-align: center;
	}
	h2 {
		font-weight: 600 !important;
	}
}
.card-collapsed {
	> :not(.card-header):not(.card-status) {
		display: none;
	}
	.card-options-collapse i:before {
		content: '\e92d';
	}
}
.card-fullscreen {
	.card-options-fullscreen i:before {
		content: '\e992';
	}
	.card-options-remove {
		display: none;
	}
}
.card-map {
	height: 15rem;
	background: $border-color;
}
.card-map-placeholder {
	background: no-repeat center;
}
.card-tabs {
	display: -ms-flexbox;
	display: flex;
}
.card-tabs-bottom .card-tabs-item {
	border: 0;
	&.active {
		border-top-color: #fff;
	}
}
.card-tabs-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: block;
	padding: 1.4rem 1.5rem;
	color: inherit;
	overflow: hidden;
}
a.card-tabs-item {
	background: #fafbfc;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
	&:focus {
		z-index: 1;
	}
	&.active {
		background: #fff;
		border-bottom-color: #fff;
	}
}
.card-status {
	position: absolute;
	top: -1px;
	left: 0;
	right: 0;
	height: 3px;
	border-radius: 7px 7px 0 0;
	background: $border-color;
}
.card-status-left {
	right: auto;
	bottom: 0;
	height: auto;
	width: 3px;
	border-radius: 3px 0 0 3px;
}
.card-icon {
	width: 3rem;
	font-size: 2.5rem;
	line-height: 3rem;
	text-align: center;
}
.card-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	margin: 0;
}
.card-alert {
	border-radius: 0;
	margin: -1px -1px 0;
}
.card-category {
	font-size: 20px;
	text-transform: capitalize;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin: 0 0 .5rem;
	background: $border-color;
	padding: 5px;
}
/*-------- Card Styles -------*/