/*-- List Style--*/

.list-style {
	list-style: inherit;
	padding-left: inherit;
	ul {
		list-style: inherit;
		padding-left: 30px;
	}
	li {
		line-height: 30px;
	}
}
.list-style2 {
	list-style: decimal;
	padding-left: inherit;
	ul {
		list-style: decimal;
		padding-left: 30px;
	}
	li {
		line-height: 30px;
	}
}
.list-style3 {
	list-style: circle !important;
	padding-left: inherit;
	ul {
		list-style: decimal;
		padding-left: 30px;
	}
	li {
		line-height: 30px;
	}
}
.list-style4 {
	list-style: lower-alpha;
	padding-left: inherit;
	ul {
		list-style: decimal;
		padding-left: 30px;
	}
	li {
		line-height: 30px;
	}
}
.order-list li {
	line-height: 30px;
}
.list-style5 {
	list-style: square;
	padding-left: inherit;
	ul {
		list-style: decimal;
		padding-left: 30px;
	}
	li {
		line-height: 30px;
	}
}
.list-style6 {
	list-style: lower-roman;
	padding-left: inherit;
	ul {
		list-style: decimal;
		padding-left: 30px;
	}
	li {
		line-height: 30px;
	}
}
/*-------- List Styles -------*/

.list-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}
.list-group-item-action {
	width: 100%;
	color: #3f3e50;
	text-align: inherit;
	&:focus, &:hover {
		color: #3f3e50;
		text-decoration: none;
		background-color: $border-color;
	}
	&:active {
		color: #cecde0;
		background-color: $border-color;
	}
}
.list-group-item {
	position: relative;
	display: block;
	padding: .75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border-color;
}
.listorder {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border-color;
	list-style-type: decimal;
	list-style-position: inside;
}
.listorder1 {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border-color;
	list-style-type: upper-alpha;
	list-style-position: inside;
}
.listunorder {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border-color;
	list-style-type: circle;
	list-style-position: inside;
}
.listunorder1 {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid $border-color;
	list-style-type: square;
	list-style-position: inside;
}
.list-group-item {
	&:first-child {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
	&:last-child {
		margin-bottom: 0;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	&:focus, &:hover {
		z-index: 1;
		text-decoration: none;
	}
	&.disabled, &:disabled {
		color: #7c90b1;
		background-color: #fff;
	}
	&.active {
		z-index: 2;
		background-color: #eef0f9;
		border: 1px solid $border-color;
		color: #3f3e50;
	}
}
.list-group-flush {
	.list-group-item {
		border-right: 0;
		border-left: 0;
		border-radius: 0;
	}
	&:first-child .list-group-item:first-child {
		border-top: 0;
	}
	&:last-child .list-group-item:last-child {
		border-bottom: 0;
	}
}
.list-group-item-secondary {
	color: #24426c;
	background-color: #cbdbf2;
	&.list-group-item-action {
		&:focus, &:hover {
			color: #24426c;
			background-color: #b7cded;
		}
		&.active {
			color: #fff;
			background-color: #24426c;
			border-color: #24426c;
		}
	}
}
.list-group-item-primary {
	color: #464a4e;
	background-color: #dddfe2;
	&.list-group-item-action {
		&:focus, &:hover {
			color: #464a4e;
			background-color: #cfd2d6;
		}
		&.active {
			color: #fff;
			background-color: #464a4e;
			border-color: #464a4e;
		}
	}
}
.list-group-item-success {
	color: #008048;
	background-color: #ccffe9;
	&.list-group-item-action {
		&:focus, &:hover {
			color: #316100;
			background-color: #c5e7a4;
		}
		&.active {
			color: #fff;
			background-color: #316100;
			border-color: #316100;
		}
	}
}
.list-group-item-info {
	color: #3ba2ff;
	background-color: #e6f1ff;
	&.list-group-item-action {
		&:focus, &:hover {
			color: #3ba2ff;
			background-color: #b3dcf9;
		}
		&.active {
			color: #fff;
			background-color: #3ba2ff;
			border-color: #3ba2ff;
		}
	}
}
.list-group-item-warning {
	color: #ffb609;
	background-color: #ffe9cc;
	&.list-group-item-action {
		&:focus, &:hover {
			color: #ffb609;
			background-color: #ffe9cc;
		}
		&.active {
			color: #fff;
			background-color: #ffb609;
			border-color: #ffb609;
		}
	}
}
.list-group-item-danger {
	color: #fe6567;
	background-color: #ffcfcc;
	&.list-group-item-action {
		&:focus, &:hover {
			color: #fe6567;
			background-color: #ffcfcc;
		}
		&.active {
			color: #fff;
			background-color: #fe6567;
			border-color: #fe6567;
		}
	}
}
.list-group-item-light {
	color: #818182;
	background-color: #fdfdfe;
	&.list-group-item-action {
		&:focus, &:hover {
			color: #818182;
			background-color: #ececf6;
		}
		&.active {
			color: #fff;
			background-color: #818182;
			border-color: #818182;
		}
	}
}
.list-group-item-dark {
	color: #1b1e21;
	background-color: #c6c8ca;
	&.list-group-item-action {
		&:focus, &:hover {
			color: #1b1e21;
			background-color: #b9bbbe;
		}
		&.active {
			color: #fff;
			background-color: #1b1e21;
			border-color: #1b1e21;
		}
	}
}
.list-inline-dots .list-inline-item+.list-inline-item:before {
	content: '· ';
	margin-left: -2px;
	margin-right: 3px;
}
.list-separated-item {
	padding: 1rem 0;
	&:first-child {
		padding-top: 0;
	}
}
.list-group-item {
	&.active .icon {
		color: inherit !important;
	}
	.icon {
		color: #cecde0 !important;
	}
}
.list-group-transparent .list-group-item {
	background: 0 0;
	border: 0;
	padding: .5rem 1rem;
	&.active {
		background: $body-color;
		font-weight: 600;
	}
}
/*-------- List Styles -------*/